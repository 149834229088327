import React from 'react'
import {NavLink} from 'react-router-dom'


function Header() {
  return (

                    <nav className="navbar navbar-expand-lg navbar-dark bg-white fixed-top">
                    <div className="container-fluid">
                        <NavLink to="/Home">
                            <img src='./images/marquee.png' alt='Marquee Simiconductor Logo' className='navbar-brand header-logo'/>
                        </NavLink>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation">
                            {/* <span className="navbar-toggler-icon"></span> */}
                            <i class="bi bi-list"></i>
                        </button>
                        <div className="collapse navbar-collapse justify-content-center" id="main_nav">
                            <ul className="navbar-nav">
                                {/* <li className="nav-item active"> <a className="nav-link" href="#">Home </a> </li>
                                <li className="nav-item"><a className="nav-link" href="#"> About </a></li>
                                <li className="nav-item"><a className="nav-link" href="#"> Services </a></li> */}
                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Company </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow " role="menu">
                                        <div className='container'>
                                            <div className="row g-3">                                            
                                                <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                    <div className="col-megamenu">
                                                        <h6 className="title">Our Company  <i class="bi bi-arrow-right-short"></i></h6>
                                                          <ul className="list-unstyled">
                                                            <li><NavLink to="/About">About Us</NavLink></li>
                                                            <li><NavLink to="/Leadership">Leadership</NavLink> </li>
                                                            {/*<li><NavLink to="/Career">Careers</NavLink> </li>*/}
                                                            
                                                            {/*<li><NavLink to="/Locations">Locations</NavLink></li>*/}
                                                            <li><NavLink to="/Partnerships">Partnerships</NavLink></li>
                                                            <li><NavLink to="/Membership">Membership</NavLink></li>
                                                            
                                                            {/*<li><NavLink to="/Downloads">Testimonials</NavLink></li>*/}
                                                            
                                                        </ul>
                                                    </div>  
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                    <div className="col-megamenu">
                                                        <h6 className="title">Collaboration <i class="bi bi-arrow-right-short"></i></h6>
                                                        <ul className="list-unstyled">
                                                            {/*<li><a href="#">Artificial Intelligence</a></li>
                                                            <li><a href="#">Image & Video Processing </a></li>*/}
                                                            <li><NavLink to="/AcademiaEngagement">Academia Engagement</NavLink></li>
                                                        </ul>
                                                    </div>  
                                                    {/*
                                                    <div className="col-megamenu mt-4">
                                                        <h6 className="title">Membership <i class="bi bi-arrow-right-short"></i></h6>
                                                        <ul className="list-unstyled">
                                                            <li><NavLink to="/GSA">GSA</NavLink></li>
                                                            <li><NavLink to="/Symbiosys">Symbiosys</NavLink></li>
                                                        </ul>
                                                    </div> */}
                                                </div>
                                                {/*<div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                    <div className="col-megamenu">
                                                        <h6 className="title">Social <i class="bi bi-arrow-right-short"></i></h6>
                                                        <ul className="list-unstyled">
                                                            <li><a href="#">Newsletter</a></li>
                                                            <li><a href="#">Blog</a></li>
                                                        </ul>
                                                    </div>  
                                                </div>  */}  
                                                <div className="col-lg-3 col-md-3 col-sm-12 p-3">

                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                    <div className="col-megamenu">
                                                        <img src='./images/about.jpg' alt='Marquee Simiconductor' className='img-fluid'/>
                                                       {/* <p className='pt-3'>providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                                                        */}
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </li>
                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Services </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">
                                        <div className='container'>
                                            <div className="row g-3">    
                                                <div className="col-lg-4 col-md-4 col-sm-12 p-3">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/Digital">
                                                        <img src='./images/Digital.jpg' alt='Marquee Simiconductor' className='img-fluid'/>
                                                        <h6 className="title">Digital Design  <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>Providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation.</p>
                                                    </NavLink>
                                                    </div>  
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12  p-3">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/Analog">
                                                        <img src='./images/Analog.jpg' alt='Marquee Simiconductor' className='img-fluid'/>
                                                        <h6 className="title">Analog & Mixed-signal Design  <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>Creating better custom chips by making circuits more reliable with low power, low leakage ... </p>
                                                    </NavLink>
                                                    </div>  
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12 p-3">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/Fpga">
                                                        <img src='./images/FGPA.gif' alt='Marquee Simiconductor' className='img-fluid'/>
                                                        <h6 className="title">FPGA Implementation   <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>Driving innovation through acceleration...</p>
                                                    </NavLink>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </li>
                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Solutions </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">                                      
                                            
                                            <div className='container'>
                                                <div className="row g-3">
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            <h6 className="title">Solutions  <i class="bi bi-arrow-right-short"></i></h6>
                                                            <ul className="list-unstyled"> 
                                                                <li><NavLink to="/AIML">AI/ML</NavLink></li>
                                                                <li><NavLink to="/Storage">Storage</NavLink></li>
                                                                <li><NavLink to="/AnalogMixedSignal">Analog & Mixed Signal</NavLink></li>
                                                                {/*<li><a href="#">Processors</a></li>
                                                                <li><a href="#">SoC</a></li>
                                                                <li><a href="#">Power Management</a></li>
                                                                <li><a href="#">Data Converters</a></li>*/}
                                                            </ul>
                                                        </div>  
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            <img src='./images/solutions.jpg' alt='Marquee Simiconductor' className='img-fluid'/>
                                                            {/*<p className='pt-3'>providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                                                            */}
                                                    
                                                        </div>  
                                                    </div>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                </div>
                                            </div>
                                        
                                    </div> 
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/Products" className="nav-link">Products</NavLink>
                                </li>
                                {/*<li className="nav-item dropdown has-megamenu">
                                    
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">In-House Automation </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">
                                        <div className='container'>
                                            <div className="row g-3">
                                                <div className="col-lg-4 col-md-4 col-sm-12 p-3">
                                                    <div className="col-megamenu Services-nav">
                                                    <NavLink to="/PSA">
                                                        <img src='./images/pm.jpg' alt='Marquee Simiconductor' className='img-fluid'/>
                                                        <h6 className="title">Project Management   <i class="bi bi-arrow-right-short"></i></h6>
                                                        <p>Your business partner for productivity and scale</p>
                                                    </NavLink>
                                                    </div>  
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div> 
                                </li>
                                                        */}

                                <li className="nav-item dropdown has-megamenu">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"> Insights </a>
                                    <div className="dropdown-menu megamenu rounded-0 border-0 shadow" role="menu">                                      
                                            
                                            <div className='container'>
                                                <div className="row g-3">
                                                    <div className='col-lg-3 col-md-3'></div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            <h6 className="title">Insights  <i class="bi bi-arrow-right-short"></i></h6>
                                                            <ul className="list-unstyled">
                                                                <li><NavLink to="/MarqueeViews">Marquee Views</NavLink></li>
                                                                <li><NavLink to="/Events">Events</NavLink></li>
                                                                <li><NavLink to="/Gallery">Gallery</NavLink></li>
                                                                <li><NavLink to="/Downloads">Downloads</NavLink></li>
                                                                <li><NavLink to="/news">News</NavLink></li>
                                                            </ul>
                                                        </div>  
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12 p-3">
                                                        <div className="col-megamenu">
                                                            <img src='./images/solutions.jpg' alt='Marquee Simiconductor' className='img-fluid'/>
                                                            {/*<p className='pt-3'>providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                                                            */}
                                                    
                                                        </div>  
                                                    </div>
                                                    <div className='col-lg-3 col-md-3'></div>
                                                </div>
                                            </div>
                                        
                                    </div> 
                                </li>

                                <li className="nav-item">
                                    <NavLink to="/Career" className="nav-link">Careers</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/Locations" className="nav-link">Contact</NavLink>
                                </li>
                            </ul>
                            {/* <ul className="navbar-nav ms-auto">
                                <li className="nav-item"><a className="nav-link" href="#"> Menu item </a></li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> Dropdown right </a>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                    <li><a className="dropdown-item" href="#"> Submenu item 1</a></li>
                                    <li><a className="dropdown-item" href="#"> Submenu item 2 </a></li>
                                    </ul>
                                </li>
                            </ul> */}
                        </div>
                    </div> 
                    </nav>


  )
}

export default Header