import React from 'react'
import { useState } from "react";
import Swal from 'sweetalert2';

function Products() {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [message, setMessage] = useState("");


    const [errorMessage, setErrorMessage] = useState("");

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Submit");

    const contactSubmit = (e) =>{
        e.preventDefault();

        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter business email');
            return false;
        }
        else
        {
            setErrorMessage("");
        }

        //return false;

        setBtnDisabled(true)
          setBtnText("Submitting...")
          fetch('https://32xpq6wqp2.execute-api.ap-south-1.amazonaws.com/sendPSAMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "user_name": firstName+ " "+lastName,
                 "user_fname": firstName,
                 "user_lname": lastName,
                 "user_email": email,
                 "user_contactNo": contactNo,
                 "user_message": message,
            })
            }).then( response => {

                Swal.fire("Success", "Mail Sent Successfully", "success");


                setFirstName("");
                setLastName("");
                setEmail("");
                setContactNo("");
                setMessage("");
                setBtnDisabled(false)
                setBtnText("Download")

                document.getElementById("btnCloseModal").click();

                
                
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error sending file.", "error");
            });

          e.target.reset();
    }

  return (
    <>

<div className='container-fluid'>
            <div className="modal fade" id="PSAContactModalID" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-success">
                            <h5 className="modal-title text-white" id="">Contact</h5>
                            <button type="button" id='btnCloseModal' className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={contactSubmit}  enctype="multipart/form-data" method="post">
                                
                            
                                <div class="row">
                                    <div class="col mb-3">
                                        <input type="text" className="form-control" id="txtFName" placeholder="First Name" name="txtFName" 
                                        onChange={(e)=>{
                                            setFirstName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    <div class="col mb-3">
                                        <input type="text" className="form-control" id="txtLName" placeholder="Last Name" name="txtLName" 
                                        onChange={(e)=>{
                                            setLastName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col">
                                    <input type="email" className="form-control" id="txtEmail" placeholder="Business Email" name="txtEmail" 
                                        onChange={(e)=>{
                                            setEmail(e.target.value)
                                            setErrorMessage("")
                                        }}
                                        required/>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" className="form-control" id="txtContactNo" placeholder="Contact No." name="txtContactNo" 
                                        onChange={(e)=>{
                                            setContactNo(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                </div>
                                
                                <div className="mb-3 mt-3">
                                    <textarea className="form-control" placeholder='Enter reson to download the brochure' rows="3" id='txtComment'
                                    onChange={(e)=>{
                                        setMessage(e.target.value)
                                    }}
                                    required
                                    ></textarea>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        {
                                            errorMessage?<>
                                            <div className='alert alert-danger'>
                                            {"Error: "+ errorMessage}
                                            </div>
                                            </>:<></>
                                        }
                                    </div>
                                </div>
    

                                <div className='row'>
                                    <div className='col-md-4 offset-md-4'>
                                        <button type="submit" className="btn btn-block btn-success"
                                         disabled={btnDisabled}>{btnText}</button>
                                    </div>
                                </div>      
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 PSA-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Products</h1>
                        <p className='blockquote'></p>
                    </div>                    
                </div>
            </div>
        </div>
        {/*
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>KEY Benifits</h3>
                    <p className='mt-4'>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” provider. The company is founded and advised by Semiconductor industry veterans, who are based in Silicon Valley USA for 25+ years, with leadership experience at many leading companies. The company focuses on 3 verticals: AI/ML implementation leveraging FPGAs and ACAP devices, Silicon/FPGA/ACAP based Digital Solution and Analog/Mixed Signal Solution. The company’s engineering strengths are in CNN / RNN, Algorithms / C++ / HLS, SystemVerilog/UVM, Digital – PCIe / DDR / DisplayPort / USB / SATA / CXL / CXS / Coherency / NVMe, Analog – Power Management / Data Converters.</p>
                    
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-md-4 text-center mt-5'>
                    <div className='drive-img'>
                        <img src='./images/domain.png' alt='Marquee Simiconductor' className=''/>
                    </div>
                    <div className='drive-text'>
                        <p className='blockquote text-mcolor mb-1 '>Domain Expertise</p>
                        <p >providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation.</p>
                    </div> 
                </div>
                <div className='col-md-4 text-center mt-5'>
                    <div className='drive-img'>
                        <img src='./images/r-d.png' alt='Marquee Simiconductor' className=''/>
                    </div>
                    <div className='drive-text'>
                        <p className='blockquote text-mcolor mb-1 '>R&D Enablement</p>
                        <p >Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                    </div> 
                </div>
                <div className='col-md-4 text-center mt-5'>
                    <div className='drive-img'>
                        <img src='./images/ip.png' alt='Marquee Simiconductor' className=''/>
                    </div>
                    <div className='drive-text'>
                        <p className='blockquote text-mcolor mb-1 '>IP-enabled Infrastructure</p>
                        <p >providing the best-in-class turnkey Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                    </div> 
                </div>
            </div>
        </div>*/}
        {/*
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5'>
                            <div className='col-md-12'>
                                
                                <p className='text-justify'>
                                Budgeting for your organization, scheduling for the team, planning for yourself and
                                encouraging reports for your customer are the KEY for project management –
                                Marquee’s corpLink.ai can run the projects successfully.  
                                </p>
                                <p>Register today to explore more.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='row'>
                                    
                                    <div className='col-md-3'>
                                        <h5>Business Call </h5>
                                        <p className='text-primary'>+63 9171290247</p>
                                        <p className='text-primary'>+91 9861762523</p>
                                    </div>
                                    <div className='col-md-3'>
                                        <h5>Contact Sales</h5>
                                        <p className='text-primary cursor-pointer' data-bs-toggle="modal" data-bs-target="#PSAContactModalID">sales@marqueesemi.com</p>
                                        <p className='text-primary cursor-pointer' data-bs-toggle="modal" data-bs-target="#PSAContactModalID">sales@corplink.ai</p>
                                    </div>
                                    <div className='col-md-3'>
                                        <h5>Product Website</h5>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        */}

<div className='container-fluid mb-2'>
    <div className='row mt-5'>
        <div className='col-md-12'>
            <div className='container'>
                <h4 className='text-blue'>Services</h4>
            </div>
        </div>
        <div className='col-md-12'>
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col-md-4'>
                        <div className="card new-shadow mb-4 p-card">
                            <img src="./images/bannersNew/digital.jpg" className="card-img-top" alt="Digital Design" />
                            <div className="card-body">
                                <h5 className="card-title">Digital Design</h5>
                                <p className='card-text productmin-height'>Providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation.</p>
                                <a href="/Digital" className="btn btn-outline-primary mt-1">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="card new-shadow mb-4 p-card">
                            <img src="./images/bannersNew/analog-mixed1920_400.jpg" className="card-img-top" alt="Analog & Mixed-signal Design" />
                            <div className="card-body">
                                <h5 className="card-title">Analog & Mixed-signal Design</h5>
                                <p className='card-text productmin-height'>Creating better custom chips by making circuits more reliable with low power, low leakage and optimized area</p>
                                <a href="/Analog" className="btn btn-outline-primary mt-1">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="card new-shadow mb-4 p-card">
                            <img src="./images/bannersNew/fpga.jpg" className="card-img-top" alt="FPGA Implementation" />
                            <div className="card-body">
                                <h5 className="card-title">FPGA Implementation</h5>
                                <p className='card-text productmin-height'>Driving innovation through acceleration...</p>
                                <a href="/Fpga" className="btn btn-outline-primary mt-1">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className='row mt-2'>
        <div className='col-md-12'>
            <div className='container'>
                <h4 className='text-blue'>Solutions</h4>
            </div>
        </div>
        <div className='col-md-12'>
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col-md-4'>
                        <div className="card new-shadow mb-4 p-card">
                            <img src="./images/bannersNew/aiml1920_400.jpg" className="card-img-top" alt="AI/ML" />
                            <div className="card-body">
                                <h5 className="card-title">AI/ML</h5>
                                <p className='card-text productmin-height'>High Level Synthesis (HLS) | OpenCV/OpenCL | E2E Run Time acceleration</p>
                                <a href="/AIML" className="btn btn-outline-primary mt-1">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="card new-shadow mb-4 p-card">
                            <img src="./images/bannersNew/storage.jpg" className="card-img-top" alt="Storage" />
                            <div className="card-body">
                                <h5 className="card-title">Storage</h5>
                                <p className='card-text productmin-height'>HIGH BW SUBSYSTEM | NOC</p>
                                <a href="/Storage" className="btn btn-outline-primary mt-1">Learn More</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="card new-shadow mb-4 p-card">
                            <img src="./images/bannersNew/analog-mixed1920_400.jpg" className="card-img-top" alt="Analog & Mixed Signal" />
                            <div className="card-body">
                                <h5 className="card-title">Analog & Mixed Signal</h5>
                                <p className='card-text productmin-height'>4500um x 4500um | 160 IOs | Max Frequency 125MHz | 0.18um Technology</p>
                                <a href="/AnalogMixedSignal" className="btn btn-outline-primary mt-1">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className='row mt-2'>
        <div className='col-md-12'>
            <div className='container'>
                <h4 className='text-blue'>Product</h4>
            </div>
        </div>
        <div className='col-md-12'>
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col-md-4'>
                        <div className="card new-shadow mb-4 p-card">
                            <img src="./images/bannersNew/psa.jpg" className="card-img-top" alt="Professional Service Automation" />
                            <div className="card-body">
                                <h5 className="card-title">Professional Service Automation</h5>
                                <p className='card-text productmin-height'>Bringing up the QUALITY. The unforgotten aspect of project management</p>
                                <a href="/Corplink" className="btn btn-outline-primary mt-1">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

        
    </>
  )
}

export default Products