import React, { useRef, useState } from 'react'
//import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
// import emailjs from '@emailjs/browser';


function Sendquery() {
    
    //const [enqnameerror, setenqnameerror] = useState()

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Send");


    const form = useRef();
    function sendEmail(e) {
        e.preventDefault(); 
        
          setBtnDisabled(true)
          setBtnText("Sending...")
          fetch('https://0nu3pz7tvd.execute-api.ap-south-1.amazonaws.com/sendContactMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "first_name": firstName,
                 "last_name": lastName,
                 "user_email": userEmail,
                 "user_subject": subject,
                 "user_message": message,
            })
            }).then( response => {

                Swal.fire("Success", "Mail Sent Successfully", "success");
                setFirstName("");
                setLastName("");
                setUserEmail("");
                setSubject("");
                setMessage("");
                setBtnDisabled(false)
                setBtnText("Send")
                
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error sending mail.", "error");
            });

          e.target.reset();
      }

    //   const changehandler = (e)=>{
    //     const inputField = e.target.name;
    //     if(inputField=='user_name'){
    //         setenqname(e.target.value);
    //         console.log(setenqname);
    //         if(!e.target.value){
    //             setenqnameerror("fill your name")
    //         }else if(e.target.value.length<4){
    //             setenqnameerror("Atleast 4 Character")
    //         }else{
    //             setenqnameerror("")
    //         }
    //     }        
    //   }
    
  return (
    <>
        <form ref={form} onSubmit={sendEmail}>
            <div className='row'>
                <div className='col-md-6'>
                    <input type="text" className="form-control" id="name" placeholder="First Name" name="user_f_name" 
                    value={firstName}
                    onChange={(e)=>{
                        setFirstName(e.target.value)
                    }}
                    required/>
                </div>
                <div className='col-md-6'>
                    <input type="text" className="form-control" id="name" placeholder="Last Name" name="user_l_name" 
                    value={lastName}
                    onChange={(e)=>{
                        setLastName(e.target.value)
                    }}
                    required/>
                </div>
            </div>
            
            {/* <div class="form-floating mb-3">
                <input type="email" class="form-control" id="email" placeholder="name@example.com" />
                <label for="email">Email address</label>
            </div> */}

            <div class="mb-3 mt-3">
                {/* <label for="email" className="form-label">Email:</label> */}
                <input type="email" className="form-control " id="email" placeholder="Email" name="user_email" 
                value={userEmail}
                onChange={(e)=>{
                    setUserEmail(e.target.value)
                }}
                required/>
            </div>
            <div className="mb-3 mt-3">
                {/* <label for="subject" className="form-label">Subject:</label> */}
                <input type="text" className="form-control" id="subject" placeholder="Subject" name="user_subject" 
                value={subject}
                onChange={(e)=>{
                    setSubject(e.target.value)
                }}
                required/>
            </div>
            <div className="mb-3">
                {/* <label for="name" className="form-label">Message:</label> */}
                <textarea class="form-control" rows="5" id="message" name="message" placeholder="Message" 
                value={message}
                onChange={(e)=>{
                    setMessage(e.target.value)
                }}
                required></textarea>
            </div>
            <button type="submit" className="btn btn-primary" value="Send" disabled={btnDisabled}>{btnText}</button>
        </form>
    </>
  )
}

export default Sendquery