import React from 'react'

function AIML() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 AIML-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Artificial Intelligence / Machine Learning</h1>
                        <p className='blockquote'>High Level Synthesis (HLS) <span className='ms-2 me-2 text-warning'>|</span> OpenCV/OpenCL <span className='ms-2 me-2 text-warning'>|</span> E2E Run Time acceleration</p>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-8 mt-5'>
                    {/*<h3 className='text-uppercase text-mcolor'>E2E RunTime Acceleration</h3>*/}
                    <h4 className='text-mcolor text-uppercase'>AI ACCELERATOR</h4>
                    <div className='text-justify'>
                        <p className='mt-4'>
                        The accelerator is designed for generic applications to compute 3D tensor convolved by 4D tensor to increase the efficiency by 10x.
                        </p>
                        <p>Key Features –</p>
                        <ul>
                            <li>Power efficient</li>
                            <li>Less on-chip memory requirement</li>
                            <li>High performance</li>
                            <li>Low latency</li>
                        </ul>
                        <p>Marquee created the microarchitecture from the specification and its own RTL design
                        and delivered the synthesizable and lint cleaned design for verification. The unit-level
                        and top-level verification was done by the in-house verification team.</p>

                        <p>
                        We developed the system C model for the accelerator and ran the complete set of Resnet Models.
                        </p>
                    </div>
                    
                    
                </div>
                <div className='col-md-4  d-flex align-items-center justify-content-center mt-5'>
                    <div>
                    <img src='./images/ai_ml_1.png' alt='Marquee Simiconductor' className='img-fluid rounded '  />
                    </div>
                    
                </div>
                <div className='col-md-12'>
                    <h4 className='text-mcolor text-uppercase mt-4'>Recuring Neural Network (RNN)</h4>
                    <div className='text-justify'>
                        <p>
                        The RNN was understood with all data paths and control paths with different interfaces such as PCIe, AXI4, and DDR4. The RTL was debugged and upgraded at subsystem level to configure Processing Elements (PEs).
                        </p>
                        <p>
                        We developed UVM testbench architecture and developed new test cases for self-checking.
                        </p>
                        <p>
                        The design is validated using Mellanox Innova2, and U25 FPGA devices. We ran
models to measure accuracy and runtime. The design was optimized to improve the
E2E runtime by more than 3x at an algorithmic level and then further improved by 51x
by accelerating time-consuming SW process to HW using the HLS Method.
                        </p>
                    </div>
                    
                </div>
                <div className='col-md-12 text-center'>
                    <img src='./images/PMUL.png' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                </div>
            </div>
            
        </div>
    </>
  )
}

export default AIML