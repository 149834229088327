import React from 'react'
import {useNavigate} from 'react-router-dom';

function Banner() {

    const navigate = useNavigate();

    const navigateToContact = () => {
        navigate('/Locations');
    };

  return (
    <>

        <div id="demo" className="carousel slide main_carousel" data-bs-ride="carousel">

        <div className="carousel-indicators">
            <button type="button" data-bs-target="#demo" data-bs-slide-to="0"  className="active"></button>
            <button type="button" data-bs-target="#demo" data-bs-slide-to="1"  ></button>
            <button type="button" data-bs-target="#demo" data-bs-slide-to="2" ></button>
            {/*<button type="button" data-bs-target="#demo" data-bs-slide-to="3" ></button>*/}
        </div>       

        <div className="carousel-inner">
            <div className="carousel-item active">
            <img src="./images/banners/1.webp" alt="Marquee Semiconductor" className="d-block"/>
            <div className="carousel-caption">
                <div className='row'>
                    <div className='col-md-7'>
                        <h1 className='display-6'>Marquee Semiconductor – Semiconductor design services for high performance connectivity</h1>
                        <p className='blockquote text-justify'>Headquartered in Silicon Valley, Marquee Semiconductor brings a distinct perspective and unique capability to semiconductor design services. 
                        We have developed a strong background in connectivity, both within modern Systems-on-Chip (SoCs) as well as between chips, and we leverage those skills for the benefit of our customers. 
                        
                        &nbsp;<a onClick={navigateToContact} className='text-warning cursor'>Contact Us</a> for more information.
                        </p>
                    </div>
                </div>
                
                {/*<p className='blockquote'>Turnkey and end-to-end Offering</p>*/}
                {/*<button className='btn btn-outline-info'>Learn More</button>*/}
            </div>
            </div>
            <div className="carousel-item">
            <img src="./images/banners/3.jpg" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
            <div className="carousel-caption">
                <div className='row'>
                    <div className='col-md-7'>
                        <h1 className='display-6 text-justify'>Build your AI/ML Vision SoC with Marquee: RTL-to-Silicon</h1>
                        <p className='blockquote text-justify'>We have the experience and expertise in high-performance AI platforms to accelerate the SoC development cycle for AI/ML applications.  &nbsp;<a onClick={navigateToContact} className='text-warning cursor'>Contact Us</a> for more information.</p>
                    </div>
                </div>
                
                {/*<p className='blockquote'>Explore with Marquee at Silicon Valley USA Bhubaneswar India, Manila Philippines and Singapore</p>*/}
                {/*<button className='btn btn-outline-info'>Learn More</button>*/}
            </div> 
            </div>
            <div className="carousel-item">
            <img src="./images/banners/2.jpg" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
            <div className="carousel-caption">
                <div className='row'>
                    <div className='col-md-7'>
                    <h1 className='display-6 text-justify'>Build your Next Chip with Marquee: Spec-to-Silicon</h1>
                    <p className='blockquote text-justify'>Our company has success bringing customers from spec-to-silicon including with key on-chip power management solutions like LDOs, data converters and switches.</p>
                    <p className='blockquote text-justify'>We excel in the integration of analog blocks with digital flow (Digital on Top) and Mixed-Signal. &nbsp;<a onClick={navigateToContact} className='text-warning cursor'>Contact Us</a> for more information. </p>
                    </div>
                </div>
                {/*<p className='blockquote'>Achieves the best-in-class power, Performance, Area and Schedule</p>*/}
                {/*<button className='btn btn-outline-info'>Learn More</button>*/}
            </div>  
            </div>
            {/*<div className="carousel-item">
            <img src="./images/banner4.jpg" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
            <div className="carousel-caption">
                <h1 className='text-uppercase display-6'>New York</h1>
                <p className='blockquote'>We love the Big Apple!</p>
                <button className='btn btn-outline-info'>Learn More</button>
            </div>  
            </div>*/}
        </div>        

        <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
            <span className="carousel-control-prev-icon"></span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
            <span className="carousel-control-next-icon"></span>
        </button>
        </div>
    </>
  )
}

export default Banner