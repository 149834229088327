import React from 'react'

function AcademiaRelation() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 AcademiaBanner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Academia Engagement</h1>
                        <p className='blockquote'>Marquee Semiconductor's relationship with academia is critical to its future.</p>
                    </div>                    
                </div>
            </div>
        </div>
        
        <div className='container-fluid p-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                {/*<h3 className='text-uppercase text-mcolor'>E2E RunTime Acceleration</h3>*/}
                                
                                <div className='text-justify'>
                                <h4 className='text-mcolor text-uppercase pb-4'>University Connection Program (UCP)</h4>
                                    <p >
                                    Every student is capable of doing something unique that can lead to great change to the
world and help engineer their future. Marquee Semiconductor enables and encourages
them how to think by asking “why” and to keep asking until they connect to the
fundamentals.
                                    </p>
                                    <p>We invite students from universities to participate in a design contest where they can
come up with new ideas and with mentorship, they will implement CMOS to take to
silicon. This acts as the fuel for the students to drive the future of the semiconductor
world.</p>
                                    
                                    <p>We train professors from the university to create the local ecosystem.</p>

                                    
                                </div>
                                
                                
                            </div>
                            <div className='col-md-6  d-flex align-items-center justify-content-center mt-5'>
                                <div>
                                <img src='./images/academia.jpg' alt='Marquee Simiconductor' className='img-fluid rounded '  />
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid p-5 bg-light'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        
                        <div className='row'>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                <div>
                                <img src='./images/academia2.jpg' alt='Marquee Simiconductor' className='img-fluid rounded '  />
                                </div>
                            </div>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                <div className='text-justify'>
                                    <h4 className='text-mcolor text-uppercase pb-4'>University R&D/Faculty Relationship</h4>
                                    <p>The company establishes relationships with professors who want to stay abreast with the industry and would like to work with an implementation partner to translate their R&D to products. The company works closely with faculties on industry projects for a win-win relationship.</p>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid p-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                       
                        <div className='row'>
                            
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                
                                <div className='text-justify'>
                                    <h4 className='text-mcolor text-uppercase pb-4'>Marquee - University Students Relationship</h4>
                                    <p >
                                    Marquee believes in generating very high-quality engineers into the semiconductor field. Most of these interns finally become full time employees of the company. Getting into an internship with Marquee is highly competitive, as the company strives to see through the future leadership of the company in them.
                                    </p> 
                                </div>
                                
                                
                            </div>
                            <div className='col-md-6  d-flex align-items-center justify-content-center mt-5'>
                                <div>
                                <img src='./images/academia3.JPEG' alt='Marquee Simiconductor' className='img-fluid rounded '  />
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>


    </>
  )
}

export default AcademiaRelation