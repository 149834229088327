import React from 'react'

function analogMixedSignal() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 AnalogMixedSol-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Analog & Mixed-signal Design</h1>
                        <p className='blockquote'>Creating better custom chips by making circuits more reliable with low power, low leakage and optimized area </p>
                    </div>                    
                </div>
            </div>
        </div>
        {/*
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>KEY Benifits</h3>
                    <p className='mt-4'>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” provider. The company is founded and advised by Semiconductor industry veterans, who are based in Silicon Valley USA for 25+ years, with leadership experience at many leading companies. The company focuses on 3 verticals: AI/ML implementation leveraging FPGAs and ACAP devices, Silicon/FPGA/ACAP based Digital Solution and Analog/Mixed Signal Solution. The company’s engineering strengths are in CNN / RNN, Algorithms / C++ / HLS, SystemVerilog/UVM, Digital – PCIe / DDR / DisplayPort / USB / SATA / CXL / CXS / Coherency / NVMe, Analog – Power Management / Data Converters.</p>
                    
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-md-4 text-center mt-5'>
                    <div className='drive-img'>
                        <img src='./images/domain.png' alt='Marquee Simiconductor' className=''/>
                    </div>
                    <div className='drive-text'>
                        <p className='blockquote text-mcolor mb-1 '>Domain Expertise</p>
                        <p >providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation.</p>
                    </div> 
                </div>
                <div className='col-md-4 text-center mt-5'>
                    <div className='drive-img'>
                        <img src='./images/r-d.png' alt='Marquee Simiconductor' className=''/>
                    </div>
                    <div className='drive-text'>
                        <p className='blockquote text-mcolor mb-1 '>R&D Enablement</p>
                        <p >Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                    </div> 
                </div>
                <div className='col-md-4 text-center mt-5'>
                    <div className='drive-img'>
                        <img src='./images/ip.png' alt='Marquee Simiconductor' className=''/>
                    </div>
                    <div className='drive-text'>
                        <p className='blockquote text-mcolor mb-1 '>IP-enabled Infrastructure</p>
                        <p >providing the best-in-class turnkey Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                    </div> 
                </div>
            </div>
        </div>*/}

        <div className='container'>
            <div className='row'>

                <div className='col-md-7 mt-5'>
                    
                    <p className='text-justify'>
                    Analog and Mixed-Signal Chip designs bring challenges for many aspects of electronics
                    system development. Optimal sizing and/or value of devices – transistors, resistors,
                    capacitors within an IC is an art and science both. This needs to meet the functional and
                    power requirements of the system at an optimal schedule and cost which requires a
                    deep understanding of the device physics along with the model parameters of the
                    process node. 
                    </p>
                    <p className='text-justify'>In order to meet the highest standard of requirement, Marquee significantly invests in its
                    analog engineers to establish their strength in fundamentals of analog design,
                    specializes in specific domains, and invests in processes, methodologies, the best
                    practices, and infrastructure, like EDA Tools and machines. Such an intrinsic disciplined
                    culture helps in maximum productivity with minimum cost.</p>
                </div>
                <div className='col-md-3 d-flex justify-content-end offset-md-1'>
                    <img src='./images/analog_Picture1.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <h4 className='text-mcolor text-uppercase fw-bold'>Platforms</h4>
                </div>
                <div className='col-md-4 d-flex justify-content-start '>
                    <img src='./images/analog_Picture2.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
                <div className='col-md-6 '>
                    
                    <ul className='listStyle mt-4'>
                        <li>{/*<i className="bi-caret-right-fill"></i>*/} Established in-house expertise to take spec -to- production with strong mentorships.</li>
                        <li>In-house reference IP platforms to accelerate the design process avoiding design from scratch.</li>
                        <li>Working experience at full-chip production level and initial test plan for post-silicon</li>
                        <li>Porting designs from one tech node to another for targeted foundries based on expertise and experiences.</li>
                        <li>Both planar and FINFET experience</li>
                        <li>Wide range of tech node: 600nm ~ 5nm</li>
                    </ul>
                </div>
                
            </div>

            <div className='row mt-4'>
                <div className='col-md-4'>
                    <h5 className='text-mcolor text-uppercase fw-bold'><i className="bi bi-cpu-fill"></i> Domain</h5>
                    <ul className='listStyle2'>
                        <li>Audio Amplifiers</li>
                        <li>PMIC</li>
                        <li>Clocking Unit (RC-Based/ XOSC)</li>
                        <li>Data Converters</li>
                        <li>SERDES</li>
                    </ul>
                </div>

                <div className='col-md-4'>
                    <h5 className='text-mcolor text-uppercase fw-bold'><i className="bi bi-cpu-fill"></i> Applications</h5>
                    <ul className='listStyle2'>
                        <li>Motor Controls</li>
                        <li>Automotive Products</li>
                        <li>Power Conversion</li>
                        <li>Programmable Devices</li>
                    </ul>
                </div>

                <div className='col-md-4'>
                    <h5 className='text-mcolor text-uppercase fw-bold'><i className="bi bi-cpu-fill"></i> Our Differentiations</h5>
                    <ul className='listStyle2'>
                        <li>Low leakage</li>
                        <li>Area optimization</li>
                        <li>Device selection</li>
                        <li>Routing mechanism</li>
                        <li>Programmability </li>
                        <li>Design Porting to lower technology nodes</li>
                        <li>Process Automation</li>
                    </ul>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <h5 className='fw-bold text-mcolor text-uppercase'>Marquee Semiconductor’s Robust Process</h5>
                    <p>To DRIVE for a successful chip design project, Marquee follows a proven robust process.</p>
                </div>
                <div className='col-md-10 offset-md-1'>
                    <img src='./images/MQS_Analog.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <h5 className='fw-bold text-mcolor text-uppercase'>Design Solutions</h5>
                    <ul className='listStyle'>
                        <li><span className='fw-bold'>Bandgap Reference :</span>
                            <ul>
                                <li><span className='fw-bold'>High Performance -</span> Constant reference voltage generator with less than ±0.25% accuracy across voltage and temperature variation.</li>
                                <li><span className='fw-bold'>Ultra-Low Power -</span> Self-biased, ultra-low current consumption reference voltage generator with ±10% accuracy across voltage and temperature variation</li>
                            </ul>
                        </li>
                        <li><span className='fw-bold'>Regulator :</span>
                            <ul>
                                <li><span className='fw-bold'>With External Capacitor -</span> Voltage regulator with built-in current limiter protection with small dropout requirement (200mV) and 10mA current capability</li>
                                <li><span className='fw-bold'>On-Chip Capacitor -</span> Voltage regulator with low current consumption, low dropout requirement, and built-in output capacitor for 2mA current capability</li>
                            </ul>
                        </li>
                        <li><span className='fw-bold'>OSC :</span>
                            <ul>
                                <li><span className='fw-bold'>RC Oscillator -</span> Stand-alone RC Oscillator with dual frequency mode of operation for 8MHz/10MHz.</li>
                                <li><span className='fw-bold'>Crystal -</span> 32KHz and 32MHz XOSC having internal amplitude control, low phase noise, and fast start-up time.</li>
                            </ul>
                        </li>
                        <li><span className='fw-bold'>A/D Converter :</span>
                            <ul>
                                <li>10-bit Auxiliary SAR ADC, with internal power management blocks, time constant tuning circuit, temperature, and input voltage sensor</li>
                            </ul>
                        </li>
                        <li><span className='fw-bold'>Power Converter :</span>
                            <ul>
                                <li>DC-DC Buck Converter</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default analogMixedSignal