import React from 'react'

function AnalogMixedSignal() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 AnalogMixedSol-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Analog & Mixed Signal</h1>
                        {/*<p className='blockquote'>providing the best-in-class turnkey end-to-end SoC solution.</p>*/}
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container pb-5'>
            <div className='row'>
                <div className='col-md-12 mt-5'>
                    <ul>
                        <li>4500um x 4500um</li>
                        <li>160 IOs</li>
                        <li>Max Frequency 125MHz</li>
                        <li>0.18um Technology</li>
                        <li>6 Metal Layers</li>
                        <li>1 POLY</li>
                        <li>Logic blocks consisting of macro cells</li>
                        <li>Programable switch for 100% routing ability</li>
                        <li>Custom delay-based Power-on reset</li>
                        <li>Different IO and core power supply(External Supply: 5volt, Core voltage: 1.8 volt)</li>
                        <li>4 Clock signals</li>
                        <li>JTAG controller</li>
                        <li>Up to 57Kbits internal Memory</li>
                        <li>Verilog-AMS model and chip-top simulation</li>
                        <li>GDSII for tape out</li>
                    </ul>
                    
                </div>
                <div className='col-md-6'>
                    <img src='./images/amd1.png' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                </div>
                <div className='col-md-6'>
                    <img src='./images/amd2.png' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                </div>
            </div>
            
        </div>
    </>
  )
}

export default AnalogMixedSignal