import React from 'react'

function Storage() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 StorageBanner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Storage</h1>
                        {/*<p className='blockquote'>providing the best-in-class turnkey end-to-end SoC solution.</p>*/}
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5'>
                    <h4 className='text-uppercase text-mcolor'>HIGH BW Subsystem</h4>
                </div>
                <div className='col-md-6'>
                    
                    <ul>
                        <li>Design specifications review
                            <ul>
                                <li>Feature extraction</li>
                            </ul>
                        </li>
                        <li>Test planning
                            <ul>
                                <li>Test item identification</li>
                                <li>Testbench and strategy development</li>
                                <li>Functional coverage plan</li>
                            </ul>
                        </li>
                        <li>Testbench component and test coding
                            <ul>
                                <li>Integrated 3rd party USB VIP</li>
                                <li>Created agents (e.g., axi, sram) and scoreboard from scratch</li>
                                <li>Developed randomizable configs and sequences</li>
                            </ul>
                        </li>
                        <li>Testing
                            <ul>
                                <li>Identified DUT issues and filed in bug tracker</li>
                                <li>Gathered and analyzed functional and rtl code coverage</li>
                            </ul>
                        </li>
                        <li>Reporting
                            <ul>
                                <li>Created detailed weekly reports</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className='col-md-6'>
                <img src='./images/ai_ml_2.jpg' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                </div>

                <div className='col-md-12 mt-5'>
                    <h4 className='text-uppercase text-mcolor'>NoC</h4>
                </div>
                <div className='col-md-12'>
                    <p><strong className='font-weight-bold'>Highly Scalable:</strong> Easily scales depending on the number of devices that needs to be connected to the system by connecting additional routers within the module.</p>
                    <p><strong className='font-weight-bold'>Multi-topology Support:</strong> Supports ring, grid / mesh, and layered / multi-stage</p>
                    <p><strong className='font-weight-bold'>Non-blocking Access:</strong> All master devices can concurrently access unique slave devices depending on the topology and routing configuration</p>
                    <p><strong className='font-weight-bold'>Independent Clocks:</strong> All devices connected to the module including the module itself have their own input clocks.</p>
                    <p><strong className='font-weight-bold'>Multi-interface Protocol Support:</strong> Interfaces such as APB5, AHB5 / AHB3-Lite, AXI3 / AXI4 / AXI4-Lite, and SRAM can be connected</p>
                    <p><strong className='font-weight-bold'>Configurable IO Data Bus Widths:</strong> Supports multiple data bus widths for both input and output ports of 32, 64, 128, 256, 512, and 1024.</p>
                    <p><strong className='font-weight-bold'>Security:</strong> Supports interface protocol-specific protection feature</p>
                    <p><strong className='font-weight-bold'>Resiliency:</strong> Supports parity enable to detect any errors in data routing and transmission</p>
                    <p><strong className='font-weight-bold'>Power Control:</strong> Supports power-islanding and power gating at the interface and router level for low power applications</p>
                </div>
                <div className='col-md-12 text-center'>
                <img src='./images/noc_1.png' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                </div>

            </div>
            
        </div>
    </>
  )
}

export default Storage