import React from 'react'

function FPGA() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 FPGABanner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>FPGA Implementation</h1>
                        <p className='blockquote'>Driving innovation through acceleration...</p>
                    </div>                    
                </div>
            </div>
        </div>

        <div className='container-fluid'>
            <div className='row p-5'>

                <div className='col-md-6 d-flex align-items-center justify-content-center offset-md-1'>
                    <div>
                    <p className='text-justify'>
                        Architectures consisting of CPUs and FPGAs have become popular as they integrate the general-purpose processing power of CPUs with energy-efficient, fine-grained parallelism of FPGAs. We develop platform-based accelerators using algorithms, methodology, and design partitioning.
                    </p>
                    <p className='text-justify'>Marquee brings up solutions for parallel accesses, irregular memory accesses, and Look-up Table (LUT) implementations through optimizations.</p>
                
                    </div>
                </div>
                <div className='col-md-4'>
                    <img src='./images/SW-FPGA-4.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
            </div>
        </div>

        <div className='container-fluid bg-light'>
            <div className='row p-5'>
                <div className='col-md-10 offset-md-1'>
                    <h4 className='text-mcolor text-uppercase fw-bold'>Platforms</h4>
                </div>
                <div className='col-md-10 offset-md-1 d-flex justify-content-center '>
                    <img src='./images/fpga2.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
            </div>

        </div>

        <div className='container-fluid'>
            <div className='row p-5'>
                <div className='col-md-10 offset-md-1'>
                    <h4 className='text-mcolor text-uppercase fw-bold'>Key Solutions</h4>
                </div>
                <div className='col-md-10 offset-md-1 d-flex justify-content-center '>
                    <img src='./images/FPGA-Solution_5.JPG' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
                <div className='col-md-10 offset-md-1 d-flex justify-content-center '>
                    <img src='./images/FPGA-Solution_6.JPG' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
                
                
                
            </div>
        </div>

        <div className='container-fluid bg-light'>
            <div className='row p-5'>
                <div className='col-md-4 offset-md-1'>
                    <h5 className='text-mcolor text-uppercase fw-bold'><i className="bi bi-cpu-fill"></i> Key Requirements</h5>
                    <ul className='listStyle2'>
                        <li>Low Latency</li>
                        <li>High Bandwidth</li>
                        <li>Lowest end-to-end run time</li>
                    </ul>
                </div>

                <div className='col-md-4'>
                    <h5 className='text-mcolor text-uppercase fw-bold'><i className="bi bi-cpu-fill"></i> Applications</h5>
                    <ul className='listStyle2'>
                        <li>RNN</li>
                        <li>CNN</li>
                        <li>Image processing</li>
                        <li>Video processing</li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default FPGA