import React from 'react'

function Digital() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 DigitalBanner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Digital Design</h1>
                        <p className='blockquote'>Providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation.</p>
                    </div>                    
                </div>
            </div>
        </div>

        <div className='container'>
            <div className='row mt-5'>
                <div className='col-md-7'>
                    <p className='text-justify'>
                        Marquee has a well-defined set of methodologies to deliver high quality work products in the following areas of digital design:
                    </p>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-7'>
                    <ul className='list-content'>
                        <li>
                            <h5 className='fw-bold text-mcolor'>Microarchitecture</h5>
                            <ul>
                                <li>Functional Specification including consideration for System Level Performance/Power</li>
                                <li>Implementation Specification including Logical/Physical Level Partitioning for Optimum Physical Implementation</li>
                            </ul>
                        </li>
                        <li>
                            <h5 className='fw-bold text-mcolor'>RTL Design</h5>
                            <ul>
                                <li>RTL Source Codes subjected to UPF Development Flow for Low Power Implementation</li>
                                <li>Comprehensive Reports with 0 Errors/Warnings
                                    <ul>
                                        <li>Lint</li>
                                        <li>Clock Domain Crossing (CDC)</li>
                                        <li>Reset Domain Crossing (RDC)</li>
                                    </ul>
                                </li>
                                <li>SDC File for Synthesis and Static Timing Analysis (STA)</li>
                                <li>Logical Equivalence Check (LEC) Report between RTL and Netlist</li>
                            </ul>
                        </li>
                        <li>
                            <h5 className='fw-bold text-mcolor'>Design Verification</h5>
                            <ul>
                                <li>Multiple Verification Strategies
                                    <ul>
                                        <li>Directed Testing</li>
                                        <li>Constrained Random Verification</li>
                                        <li>Property Check using Formal Verification (FV)</li>
                                    </ul>
                                </li>
                                <li>Detailed Test Plan indicating the Verification Strategy, Test Items, Test Algorithm, and Functional Coverage Information</li>
                                <li>UVM-Compliant Test Environment and Source Codes</li>
                                <li>100% Functional and RTL Coverage</li>
                            </ul>
                        </li>
                        <li>
                            <h5 className='fw-bold text-mcolor'>Design for Test (DFT)</h5>
                            <ul>
                                <li>Chip and Unit Level DFT Architecture</li>
                                <li>Multiple Verification Strategies</li>
                                <li>Automatic Test Pattern Generation (ATPG)</li>
                                <li>Memory Built-In Self-Test (MBIST)</li>
                                <li>Logic Built-In Self-Test (LBIST)</li>
                                <li>Boundary Scan</li>
                                <li>At least 90% Fault Coverage</li>
                            </ul>
                        </li>
                        <li>
                            <h5 className='fw-bold text-mcolor'>Physical Design (PD)</h5>
                            <ul>
                                <li>Complete RTL to GDSII Flow that meets the required Performance, Power, Timing, and Area</li>
                                <li>Design Rule Check (DRC) and Layout Versus Schematic (LVS) Clean Designs</li>
                                <li>Close Coordination with Analog Layout for Chip Signoff</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className='col-md-5 mt-5'>
                    <img src='./images/Digital_2.jpg' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
            </div>


            <div className='row mt-3'>
                <div className='col-md-12'>
                    <h4 className='fw-bold text-mcolor text-uppercase'>Domain Expertise</h4>
                </div>
                <div className='col-md-8 offset-md-2'>
                    <img src='./images/Digital_3.jpg' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-md-12'>
                    <h4 className='fw-bold text-mcolor text-uppercase'>Tool Expertise</h4>
                </div>
                <div className='col-md-3'>
                    <img src='./images/synopsys.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
                <div className='col-md-4'>
                    <img src='./images/mentor2.jfif' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
                <div className='col-md-3'>
                    <img src='./images/cadence1.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
                
            </div>
            
        </div>
    </>
  )
}

export default Digital