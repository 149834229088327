import React from 'react'
import Banner from './Banner'
import {useNavigate} from 'react-router-dom';
import DownloadProductInfo from '../Company/downloadProductInfo'

function Home() {

    const navigate = useNavigate();

    const navigateToAbout = () => {
        navigate('/About');
    };

    const navigateToAIML = () => {
        navigate('/AIML');
    };

    const navigateToStorage = () => {
        navigate('/Storage');
    };

    const navigateToAnalogMixedSignal = () => {
        navigate('/AnalogMixedSignal');
    };

  return (
    <>
        <Banner/>
       <div className='container-fluid bg-light pt-5 pb-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                <div className=''>
                                    <h4 className='text-uppercase  text-mcolor'>We Are...</h4>
                                    <p className='text-justify'>
                                    Headquartered in Silicon Valley, Marquee Semiconductor brings a distinct perspective and unique capability to semiconductor design services. 
                                    We have developed a strong background in connectivity, both within modern Systems-on-Chip (SoCs) as well as between chips, and we leverage those skills for the benefit of our customers. 
                                    More than just a general design team, we at Marquee have invested in equipping our world-class global team with deep expertise in connectivity and a unique engineering culture, discipline, and methodology to bring our customers’ products to success on time and on budget. We service our valued customers around the world with engineering resources not just from the USA but also from India, Philippines, Singapore, and Malaysia.</p>
                                    
                                    <button className='btn btn-outline-info' onClick={navigateToAbout}>Learn More</button>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <img src='./images/about.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>

       <div className='container  pt-5 pb-5'>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <h3 className='text-uppercase text-mcolor'>Technological abilities that connect us with our customers…</h3>
                   {/* <p>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” </p> */}
                </div>
            </div>

            <div className='row'>
                <div className='col-md-8 d-flex align-items-center justify-content-center'>
                    <div className=''>
                        <p className='text-uppercase blockquote text-mcolor'>NETWORK-ON-CHIP BASED SOC INTEGRATION</p>
                       {/*} <p className='text-justify'>
                        Our innovative, high-scalable solution for both coherent and non-coherent Network-on-Chip (NoC) with multi-interface protocol support adds value for SoC world to overcome biggest challenges by helping customers for many applications such as automotive and accelerating artificial intelligence (AI). With the growing complexity of integrated systems, NoC provides enhanced performance.</p>*/}
                        <p className='text-justify'>We have the expertise in creating both coherent and non-coherent Network-on-Chip (NoC) based subsystems and platforms - integrating several of these to create chiplets that can scale.</p>
                        <button className='btn btn-outline-info' onClick={navigateToStorage}>Learn More</button> 
                    </div>
                </div>
                <div className='col-md-4 p-3'>
                    <img src='./images/noc-1024-2.jpg' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                </div>
            </div>

            <div className='row mt-2'>
                <div className='col-md-4 p-3'>
                    <img src='./images/AI-ML-2.jpg' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                </div>
                <div className='col-md-8 d-flex align-items-center justify-content-center'>
                    <div className=''>
                        <p className='text-uppercase blockquote text-mcolor'>AI/ML Acceleration</p>
                        <p className='text-justify'>
                        A cost-effective multi-tenant neural network execution is the most important design goal for modern neural network accelerators. Emerging AI services consist of many heterogeneous neural network executions. AI accelerator for improving its cost effectiveness is a challenge. Next-generation neural network accelerator design supports a simultaneous multi-neural network execution, while fully utilizing its hardware resources with an improved end-to-end run time by implementation of look-up Tables (LUTs).
                        </p>
                        <button className='btn btn-outline-info' onClick={navigateToAIML}>Learn More</button> 
                    </div>
                </div>
                
            </div>

            <div className='row mt-2'>
                <div className='col-md-8 d-flex align-items-center justify-content-center'>
                    <div className=''>
                        <p className='text-uppercase blockquote text-mcolor'>Programmable Device Platform</p>
                        <p className='text-justify'>
                        Creating better custom chips by making circuits more reliable with low power, low leakage, and optimized area.
                        </p>
                        <p className='text-justify'>
                        Successfully implemented complex programable Logic Devices to replicate the older products with better Power, performance, and Area (PPA). We customized the blocks to handle different IO and core supplies. Verilog-AMS models are developed and we perform chip-top simulation for Analog and Mixed-Signal verification.
                        </p>
                        <button className='btn btn-outline-info' onClick={navigateToAnalogMixedSignal}>Learn More</button> 
                    </div>
                </div>
                <div className='col-md-4 p-3 text-right'>
                    <img src='./images/Analog-solutions-2.jpg' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-md-4 p-3'>
                    <img src='./images/new1.jpg' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                </div>
                <div className='col-md-8 d-flex align-items-center justify-content-center'>
                    <div className=''>
                        <p className='text-uppercase blockquote text-mcolor'>Secure Your Data with Marquee’s ECC Solutions</p>
                        <p className='text-justify'>
                        We have our own silicon proven multi-bit error correction and detection for communication systems. The parametric design can configure to correct 4-bit error and can correct 5-bit errors for a 32-bit data. Generic architecture can be used for wider data bits.
                        </p>
                        <DownloadProductInfo productName={"ECC Solutions"} produtFileName="ecc_product_brief_1.2.pdf" produtFileDownloadName="ecc_product_brief_1.2.pdf" />
                        {/*<button className='btn btn-outline-info' onClick={navigateToAIML}>Learn More</button> */}
                     
                    </div>
                </div>
                
            </div>

            {/*
            <div className='row mb-5 mt-3'>
                <div className='col-md-4 '>
                    <div className='card p-5 m-2 hoversection'>
                        <img src='./images/AIML.jpg' alt='AI/ML' className='img-fluid image'/>
                        <div className="overlay rounded">
                            <div className="text">
                                <p className='blockquote'>AI/ML Acceleration Solutions</p>
                                    <div>
                                        <p>High Level Synthesis (HLS)</p>
                                        <p>OpenCV/OpenCL</p>
                                        <p>E2E Run Time acceleration</p>
                                    </div>
                                
                                <button className='btn btn-outline-info' onClick={navigateToAIML}>Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='card p-5 m-2 hoversection'>
                        <img src='./images/Digital.jpg' alt='AI/ML' className='img-fluid image'/>
                        <div className="overlay rounded">
                            <div className="text">
                                <p className='blockquote'>Digital Design Solutions</p>
                                <p>Providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                                <button className='btn btn-outline-info' onClick={navigateToStorage}>Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='card p-5 m-2 hoversection'>
                        <img src='./images/Analog.jpg' alt='AI/ML' className='img-fluid image'/>
                        <div className="overlay rounded">
                            <div className="text">
                                <p className='blockquote'>Analog and Mixed Signal Solutions</p>
                                <p>Creating better custom chips by making circuits more reliable with low power, low leakage and optimized area.</p>
                                <button className='btn btn-outline-info' onClick={navigateToAnalogMixedSignal}>Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
       </div>
       <div className='container-fluid bg-light pt-5 pb-5 whyMarquee'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 mt-3 text-center'>
                                <h3 className='text-uppercase text-mcolor'>Why Marquee Semiconductor – Our Differentiation</h3>
                                {/*<p className='drive-content'>Focused on Technical and Operational excellence in <span>DRIVE</span> model</p>*/}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h5>We have a deep understanding of connectivity, including:</h5>
                                <div className='row mt-4'>
                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-body'>
                                            <strong className='text-mcolor'> Interfaces:</strong> PCIe, CXL, UCIe, DDR, SATA, CAN 
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-body'>
                                            <strong className='text-mcolor'> Intrachip Protocols:</strong> NoC, AMBA CHI/AXI/AHB/APB, NVMe
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-body'>
                                            <strong className='text-mcolor'> Physical connectivity:</strong> clocking, Power, timing management and control
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-body'>
                                            <strong className='text-mcolor'> Chiplet</strong> based Homogeneous and Heterogeneous Systems
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<ul>
                                    <li>Interfaces: PCIe, CXL, UCIe, DDR, SATA, CAN </li>
                                    <li>Intrachip Protocols: NoC, AMBA CHI/AXI/AHB/APB, NVMe</li>
                                    <li>Physical connectivity: clocking, Power, timing management and control</li>
                                    <li>Chiplet based Homogeneous and Heterogeneous Systems</li>
                                </ul>*/}
                                
                            </div>
                        </div>
                        
                        {/*<div className='row'>
                            
                            <div className='col-md-2 text-center mt-5'>
                                <div className='drive-img'>
                                    <img src='./images/domain.png' alt='Marquee Simiconductor' className=''/>
                                </div>
                                <div className='drive-text'>
                                    <p className='text-mcolor mb-1'><span>D</span>omain Expertise</p>
                                </div> 
                            </div>
                            <div className='col-md-2 text-center mt-5'>
                                <div className='drive-img'>
                                    <img src='./images/r-d.png' alt='Marquee Simiconductor' className=''/>
                                </div>
                                <div className='drive-text'>
                                    <p className='text-mcolor mb-1 '><span>R</span>&D Enablement</p>
                                </div> 
                            </div>
                            <div className='col-md-3 text-center mt-5'>
                                <div className='drive-img'>
                                    <img src='./images/ip.png' alt='Marquee Simiconductor' className=''/>
                                </div>
                                <div className='drive-text'>
                                    <p className='text-mcolor mb-1 '><span>I</span>P-enabled Infrastructure</p>
                                    
                                </div> 
                            </div>
                            
                            <div className='col-md-2 text-center mt-5'>
                                <div className='drive-img'>
                                    <img src='./images/velocity.png' alt='Marquee Simiconductor' className=''/>
                                </div>
                                <div className='drive-text'>
                                    <p className='text-mcolor mb-1 '><span>V</span>elocity of Execution</p>
                                   
                                </div> 
                            </div>
                            <div className='col-md-3 text-center mt-5'>
                                <div className='drive-img'>
                                    <img src='./images/ecosystem.png' alt='Marquee Simiconductor' className=''/>
                                </div>
                                <div className='drive-text'>
                                    <p className=' text-mcolor mb-1 '><span>E</span>cosystem Partnership</p>
                                    
                                </div> 
                            </div>
                            
                        </div> */}
                    </div>
                </div>
            </div>
       </div>

       {/*<div className='container-fluid  pt-5 pb-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 d-flex align-items-center justify-content-center '>
                                <div className=''>
                                    <p className='text-uppercase blockquote text-mcolor'>We stay invested in 3P’s – <br/>Marquee’s Intrinsic Value System</p>
                                    <h4 className='text-mcolor'>People</h4>
                                    <ul>
                                        <li>Technology driven resources with expertise in Microarchitecture</li>
                                        <li>System-level design and verification</li>
                                        <li>FPGA Validation and running applications</li>
                                        <li>Custom analog design, verification and layout</li>
                                    </ul>
                                    <h4  className='text-mcolor'>Process</h4>
                                    <ul>
                                        <li>Thinking for customer’s success</li>
                                        <li>Product and design success through usage models, thorough architectural analysis and robust verification process</li>
                                        <li>Customization based-on area and performance optimization requirements</li>
                                        <li>Script-based automation brings productivity and timely delivery</li>
                                    </ul>
                                    <h4  className='text-mcolor'>Program</h4>
                                    <ul>
                                        <li>In-house project management tool for effective and live tracking of project execution.</li>
                                        <li>Customer interface and incremental status monitoring</li>
                                        <li>Resource allocation and qualitative report generation with graphical visualization to measure Quality, Cost and Delivery (QCD)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <img src='./images/ppp.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div> */}

        <div className='container-fluid  pt-5 pb-5 iit-box'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                            <p className='text-uppercase blockquote text-mcolor'>Our I.I.T. Approach to Design Services: Innovation, Integrity, Transparency</p>
                            </div>
                            <div className='col-md-4'>
                                
                                <div className="card text-center">
                                    
                                    <div className='card-body'>
                                    <img src="./images/domain.png" className="card-img-top" alt="Marquee semiconductor" />
                                        <h4 className="card-title text-mcolor">Innovation</h4>

                                        <div className='text-start'>
                                            <p>
                                            We have developed and utilize unique automation software to manage all our project work to reduce schedule risk: 
                                            <ul className='mt-2'>
                                                <li>Professional Service Automation tool (PSA)</li>
                                                <li>Register Service Automation tool (RSA)</li>
                                                <li>System Integration Automation tool (SIA)</li>
                                            </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div className='col-md-4'>
                                
                                <div className="card  text-center">
                                    
                                    <div className='card-body'>
                                        <img src="./images/ecosystem.png" className="card-img-top" alt="..." />
                                        <h4 className='text-mcolor'>Integrity</h4>
                                        <div className='text-start'>
                                        
                                        <p>Our engineers are trained to work with the highest degree of integrity to ensure on time & on budget delivery; no surprises</p>
                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                
                                <div className="card text-center">
                                
                                    <div className='card-body'>
                                        <img src="./images/r-d.png" className="card-img-top" alt="..." />
                                        <h4 className='text-mcolor'>Transparency</h4>
                                        <div className='text-start'>
                                            <p>We provide customers with real-time tracking of all aspects of our project work through our automation tools.</p>
                                    
                                        </div>
                                    
                                    </div>
                                </div>
                                
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
       </div>

       <div className='container-fluid bg-light pt-5 pb-5'>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <h3 className='text-uppercase text-mcolor'>ECOSYSTEM PARTNERSHIPS</h3>
                    {/*<p>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” </p>*/}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <div id="demo1" className="carousel slide pattner_carousel" data-bs-ride="carousel">

                    {/* <div className="carousel-indicators">
                        <button type="button" data-bs-target="#demo1" data-bs-slide-to="0"  className="active"></button>
                        <button type="button" data-bs-target="#demo1" data-bs-slide-to="1"  ></button>
                        <button type="button" data-bs-target="#demo1" data-bs-slide-to="2" ></button>
                        <button type="button" data-bs-target="#demo1" data-bs-slide-to="3" ></button>
                    </div>        */}

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                        <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                        <div className="carousel-caption">
                            <div className='row'>
                                <div className='col-md-3'><img src="./images/patner1_signatureip.png" alt="Marquee Semiconductor" className="img-fluid"/></div>
                                <div className='col-md-3'><img src="./images/avery.png" alt="Marquee Semiconductor" className="img-fluid"/></div>
                                <div className='col-md-3'><img src="./images/mentor.png" alt="Marquee Semiconductor" className="img-fluid mt-2"/></div>
                                <div className='col-md-3'><img src="./images/AMD_Adaptive_Computing_Partner_Badge_SelectCert.png" alt="Marquee Semiconductor" className="img-fluid"/></div>
                                
                            </div>
                        </div>
                        </div>
                        <div className="carousel-item">
                        <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                        <div className="carousel-caption">
                            <div className='row'>
                            <div className='col-md-3'><img src="./images/partner3.png" alt="Marquee Semiconductor" className="img-fluid"/></div>
                                <div className='col-md-3'><img src="./images/SerialTek-Logo.png" alt="Marquee Semiconductor" className="img-fluid mt-4"/></div>
                                <div className='col-md-3'><img src="./images/cadence.png" alt="Marquee Semiconductor" className="img-fluid"/></div>
                                
                                <div className='col-md-3'><img src="./images/synopsys.png" alt="Marquee Semiconductor" className="img-fluid mt-4"/></div>
                                
                            </div>
                        </div> 
                        </div>

                    </div>        

                    <button className="carousel-control-prev text-secondary" type="button" data-bs-target="#demo1" data-bs-slide="prev">
                        <i class="bi bi-chevron-left"></i>
                    </button>
                    <button className="carousel-control-next text-secondary" type="button" data-bs-target="#demo1" data-bs-slide="next">
                        <i class="bi bi-chevron-right"></i>
                    </button>
                    </div>

                    <div id="demo2" className="carousel slide pattner_carousel_sm" data-bs-ride="carousel">

                    {/* <div className="carousel-indicators">
                        <button type="button" data-bs-target="#demo1" data-bs-slide-to="0"  className="active"></button>
                        <button type="button" data-bs-target="#demo1" data-bs-slide-to="1"  ></button>
                        <button type="button" data-bs-target="#demo1" data-bs-slide-to="2" ></button>
                        <button type="button" data-bs-target="#demo1" data-bs-slide-to="3" ></button>
                    </div>        */}

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                            <div className='carousel-caption'><img src="./images/partner1.png" alt="Marquee Semiconductor" className="img-fluid"/></div>   
                        </div>
                        <div className="carousel-item">
                            <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                            <div className='carousel-caption'><img src="./images/avery.png" alt="Marquee Semiconductor" className="img-fluid"/></div>  
                        </div>
                        <div className="carousel-item">
                            <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                            <div className='carousel-caption'><img src="./images/mentor.png" alt="Marquee Semiconductor" className="img-fluid mt-2"/></div>
                        </div>
                        <div className="carousel-item">
                            <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                            <div className='carousel-caption'><img src="./images/AMD_Adaptive_Computing_Partner_Badge_SelectCert.png" alt="Marquee Semiconductor" className="img-fluid"/></div>
                        </div>
                        <div className="carousel-item">
                            <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                            <div className='carousel-caption'><img src="./images/partner3.png" alt="Marquee Semiconductor" className="img-fluid"/></div>    
                        </div>
                        <div className="carousel-item">
                            <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                            <div className='carousel-caption'><img src="./images/SerialTek-Logo.png" alt="Marquee Semiconductor" className="img-fluid"/></div>    
                        </div>
                        <div className="carousel-item">
                            <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                            <div className='carousel-caption'><img src="./images/cadence.png" alt="Marquee Semiconductor" className="img-fluid"/></div>
                        </div>
                        
                        <div className="carousel-item">
                            <img src="./images/blankbg.png" alt="Marquee Semiconductor" className="d-block" style={{width:"100%"}}/>
                            <div className='carousel-caption'><img src="./images/synopsys.png" alt="Marquee Semiconductor" className="img-fluid mt-4"/></div>
                        </div>

                    </div>        

                    <button className="carousel-control-prev text-secondary" type="button" data-bs-target="#demo2" data-bs-slide="prev">
                        <i class="bi bi-chevron-left"></i>
                    </button>
                    <button className="carousel-control-next text-secondary" type="button" data-bs-target="#demo2" data-bs-slide="next">
                        <i class="bi bi-chevron-right"></i>
                    </button>
                    </div>
                </div>
            </div>
       </div>


    </>
  )
}

export default Home