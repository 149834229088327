import React from 'react'

//import { useState } from "react";
//import Swal from 'sweetalert2';

function News() {


  return (
    <>

        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 Events-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>News</h1>
                        <p className='blockquote text-info d-none'>Achieves Best-In-Class Power Performance, Area, Schedule</p>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
           {/*
            <div className='row'>
                <div className='col-md-12 mt-5 mb-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>Downloads</h3>
                    <p>
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                    </p>
                </div>
            </div> */}
            {/*
            <div className='row'>
                <div className='col-md-12'>
                <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Tittle</th>
                                <th scope="col">Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>providing the best-in-class turnkey end-to-end SoC solution</td>
                                <td><Link to="/downloads/Marquee.pdf" className='btn btn-sm btn-success' target="_blank" download><i class="bi bi-download"></i>  Download</Link>
                                
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>*/}
        </div>

        <div className='container-fluid pt-5'>
            <div className='row'>

                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card news-block'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-2 news-dates'>

                                                <h6>April 09</h6>
                                                <h6>2024,</h6>
                                                
                                            </div>
                                            <div className='col-md-10 news-body'>
                                                <h4>MarqueeSemi Unveils Professional Services Automation Tool</h4>
                                                <h5 className='pb-3 fst-italic fw-light'>SaaS-based corpLink.ai™ enables transparency and accountability for engineering project management.</h5>
                                                
                                                <p className='text-justify'><span>SINGAPORE, April 9, 2024/<a href='https://world.einnews.com/pr_news/702187670/marqueesemi-unveils-professional-services-automation-tool' target='_blank'>EINPresswire.com</a>/</span> -- Marquee Semiconductor, an emerging global leader in chip design solutions, is excited to announce the launch of its latest offering: a Professional Services Automation tool called corpLink.ai. This simple, yet powerful productivity and accountability tool is provided in a SaaS model and is designed to adapt to support scaling of any customers’ business -an indispensable asset for CEOs and CIOs alike.</p>
                                                <p className='text-justify'><strong>corpLink.ai</strong> is a comprehensive professional service automation tool that drives operational excellence by encouraging best practices to deliver unparalleled and measurable productivity during project development. Its built-in domain-specific template, if available, enables a quick adoption of the tool in the required domain. As we execute projects across global sites, project management extends beyond mere milestones and tasks. It requires communication with HR to source talent, with IT to source and manage the hardware and software. Moreover, there is a need to manage the project requirements, documentation, meetings, issues, team and customer communications, status, and report generation in one place. The customer portal feature gives a seamless experience in client-vendor relationships through real-time communication, cooperation, and trust.</p>
                                                
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <p>
                                                            Key Structural Features of corpLink.ai Tool includes:
                                                            <ul>
                                                                <li>Modular Solution: Customizable modules to meet diverse business requirements.</li>
                                                                <li>Integrated Solution: Seamlessly integrates with your existing system.</li>
                                                                <li>3rd Party API Support: Offers compatibility with external applications.</li>
                                                                <li>Security, Speed, and Customizability: Ensures secure, fast, optimized, and customizable solutions tailored to individual business needs.</li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <img src='./images/diagram-3-corplink.png' alt='corpLink.ai' className='img-fluid p-3'/>
                                                    </div>
                                                </div>
                                                
                                                
                                                <p className='text-justify'>Purna Mohanty, CEO of Marquee Semiconductor, stated “corpLink.ai is an easy-to-use and intuitive tool designed to run complex projects in a simple way. It is currently proven and time-tested across projects and customers in semiconductor domain. We listen to our customers and employees, invest in R&D to bring in a differentiated experience executing a project. We are happy to roll it out to the world.”</p>
                                                <p className='text-justify'>Ang Wee Seng, Executive Director of Singapore Semiconductor Industry Association (SSIA), stated “We are happy that our member company, MarqueeSemi,released their unique SaaS-based productivity and accountability tool, corpLink.ai, in Singapore. This is an end-to-end professional service automation tool that brings in productivity across board for a small and medium enterprise (SME). This is the first of its kind in the industry that is targeted to manage an SME’s operation end-to-end. It is a must for CEOs and CIOs who require an innovative dashboard that gives them a daily report of the health of their enterprise. SSIA is committed to support such initiatives by its member companies.”</p>
                                                <p>MarqueeSemi and its customers have been using this across all their projects with a focus on silicon domain. As an additional benefit to our silicon customers, the tool enables a silicon domain specific checklist that brings in another level of productivity.</p>
                                                
                                                <h6>Informative Dashboard for Enhanced Productivity</h6>
                                                <p className='text-justify'>The hallmark feature of corpLink.ai is its role-specific dashboard, meticulously crafted to cater to diverse user requirements. This customizable dashboard streamlines access to critical information, ensuring efficient navigation and empowering users with real-time insights. By organizing frequently accessed data in both graphical and tabular formats,corpLink.ai optimizes workflow efficiency and productivity.</p>
                                            
                                                <h6>Key Value Propositions of corpLink.ai:</h6>
                                                <p>Information at the fingertips: A dashboard designed for CIOs and CEOs to keep track of the company’s operation.
                                                    <br/>
                                                    Designed for diverse business needs: tailored to accommodate startups to mid-level companies, corpLink.ai adapts and scales with your business.
                                                    <br/>
                                                    Long-term cost savings: corpLink.ai results the highest long-term savings by streamlining operations and maximizing resource utilization.
                                                </p>
                                                <h6>Product link:</h6>
                                                <a href='https://corplink.ai/' target='_blank'>https://www.corplink.ai</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card news-block'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-2 news-dates'>

                                                <h6>Jan 23</h6>
                                                <h6>2024,</h6>
                                                
                                            </div>
                                            <div className='col-md-10 news-body'>
                                                <h4>Marquee Semiconductor Engages Industry Veteran Gideon Intrater as Strategic Advisor</h4>
                                                
                                                <p className='text-justify'><span>MILPITAS, CALIFORNIA, UNITED STATES, January 23, 2024/<a href='https://www.einpresswire.com/article/682569847/marquee-semiconductor-engages-industry-veteran-gideon-intrater-as-strategic-advisor' target='_blank'>EINPresswire.com</a>/</span> -- Marquee Semiconductor, a global leader in chip design solutions, announces it has brought on semiconductor industry veteran Gideon Intrater as a Strategic Advisor. Mr. Intrater brings to Marquee more than 30 years of experience gained through technical, marketing and sales leadership roles at semiconductor, intellectual property, and systems companies.</p>
                                                <p className='text-justify'>Marquee provides spec-to-silicon design services in semiconductors with a focus on high-speed connectivity protocols, chiplet-based designs, and AI/ML accelerators, leveraging its deep analog and digital design expertise. The engagement with Mr. Intrater comes as the company has recently expanded its global presence, achieved ISO 9001 certification for its India R&D center, and is looking to extend the value it provides to customers through new service offerings.</p>
                                                <p className='text-justify'>“We’re delighted to be able to benefit from Gideon’s expertise as we continue to grow our global footprint as a leading provider of design services for complex mixed-signal SoCs,” said Purna Mohanty, CEO, Marquee Semiconductor. “Gideon has a proven track record with companies across the semiconductor value chain, including design services companies. His experience with both established companies and startups, as well as his system-level understanding, will be valuable assets to the company.”</p>
                                                <p className='text-justify'>“I am impressed with Marquee’s unique expertise and advanced design services capabilities for cutting-edge devices,” said Mr. Intrater. “As we enter a new era where AI and machine learning are drivers of the next wave of semiconductors, Marquee can help companies accelerate their development cycle. I am particularly excited about the unique service offering the company is preparing to roll out, designed to increase customer transparency and speed time to market for complex SoCs.”</p>
                                                <h6>About Gideon Intrater</h6>
                                                <p className='text-justify'>Gideon Intrater serves as a strategic advisor to several public and private companies. Until recently, he was a VP of Strategy and Technology at Renesas Corp. through its acquisition of Dialog Semiconductor. Before joining Dialog, he served as CTO of Adesto (NASDAQ:IOTS). He previously served on the advisory boards of Think Silicon (acquired by Applied Materials in 2020), Centipede Semi and Sansa Security (acquired by ARM in 2015). Before that, Mr. Intrater was VP of Marketing at MIPS Technologies (NASDAQ:MIPS) until the company was sold in 2013. Prior to MIPS, Mr. Intrater was VP of Architecture for Symwave, a privately held supplier of high-performance analog/mixed signal semiconductor solutions for consumer devices. Mr. Intrater previously held various management positions at MIPS and National Semiconductor Corp. He holds over 40 issued patents. He earned BSEE and MSEE degrees from the Technion, Israel Institute of Technology, and an MBA from San Jose State University.</p>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card news-block'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-2 news-dates'>

                                                <h6>October 03</h6>
                                                <h6>2023,</h6>
                                                
                                            </div>
                                            <div className='col-md-10 news-body'>
                                                <h4>Marquee Semiconductor Joins Singapore Semiconductor Industry Association to Catalyze Development of Singaporean Hub</h4>
                                                
                                                <p className='text-justify'><span>MILPITAS, CALIFORNIA, UNITED STATES, October 03, 2023/<a href='https://www.einnews.com/amp/pr_news/659190476/marquee-semiconductor-joins-singapore-semiconductor-industry-association-to-catalyze-development-of-singaporean-hub' target='_blank'>EINPresswire.com</a>/</span> -- Marquee Semiconductor , a global leader in chip design solutions, is delighted to announce its collaboration with the Singapore Semiconductor Industry Association (SSIA), a pivotal step that reflects Marquee's commitment to fostering the growth and advancement of the semiconductor ecosystem in Singapore. SSIA is the voice of Singapore’s semiconductor industry and is committed to support this important sector in Singapore in order to facilitate substantial growth of the whole semiconductor economy of the country.</p>
                                                <p className='text-justify'>By becoming a member of the SSIA, Marquee Semiconductor reinforces its investment to promoting innovation, and technological excellence within the Singaporean semiconductor landscape. This partnership aims to leverage Marquee's experience and expertise to drive the development of cutting-edge solutions that will further elevate Singapore's position as a hub for semiconductor innovation.</p>
                                                <p className='text-justify'>Marquee Semiconductor, headquartered in Silicon Valley, provides spec to silicon design services in semiconductor with a specialization and focus on high-speed connectivity protocols and chiplet-based designs. Since its inception in 2021, the company’s approach has been very unique, both top-down and bottom-up, in scaling up its R&D efforts in Singapore. In the top-down approach, the company strives to hire experienced engineers to drive the customer solution and support from Singapore. In the bottom-up approach, the company’s connection with local universities support to hire top talents that go through company’s state-of-the-art “Learning Management System (LMS)” for engineering and culture training. Such an approach inculcates a habit to innovate, supports scalability of the R&D center, and thus the long-term growth of the company in the region.</p>
                                                <p className='text-justify'>“Singapore has a vibrant and sophisticated semiconductor industry that continues to be a key pillar of our economy. We are pleased to have Marquee be part of our ecosystem to contribute to the growth of the sector and nurture local talents. In this regard, industry associations such as the SSIA play a key role in facilitating connections and knowledge exchange amongst companies and I look forward to more new collaborations between Marquee and our local ecosystem players.” said Ms. Elaine Teo, Senior Vice President, Investment Facilitation Division, Singapore Economic Development Board.</p>
                                                <p className='text-justify'>“We are delighted to welcome Marquee Semiconductor as the newest addition to our esteemed network of members at SSIA. Their presence will further enhance the semiconductor ecosystem in Singapore and the region. We are excited about the opportunity to collaborate with Marquee Semiconductor to further grow our ecosystem. With their cutting-edge technology and innovative solutions, we are confident that they will contribute immensely to the growth of our industry. Additionally, we are committed to working closely with them, providing the necessary support to scale their operations here.” Expressed, Ang Wee Seng, Executive Director of SSIA.</p>
                                                <p className='text-justify'>“Through this collaboration with SSIA along with company’s strong R&D presence in Silicon Valley, and other global locations, the company is well positioned in Singapore to scale-up a team of high caliber workforce and work with local customers and partners to develop chiplet-based solution. Marquee Semiconductor will actively participate in knowledge-sharing initiatives, industry events, and collaborative projects aimed at propelling the growth of the semiconductor ecosystem in Singapore.” Said Purna Mohanty, CEO, Marquee Semiconductor.</p>
                                                <h6>About SSIA</h6>
                                                <p className='text-justify'>As Singapore Semiconductor Industry Association (SSIA) has more than 250 members today including companies and organizations throughout all parts of the complex and comprehensive value chain - IC design companies, Manufacturers, Fabless companies, Equipment suppliers, Photovoltaic companies, EDA and material suppliers, Training and service providers, IP companies, research institutes and Academia, as well as individual members. Since 2013, SME membership has grown exponentially and SMEs now account for close to half of SSIA’s membership. For more information about SSIA, visit: <a href='https://ssia.org.sg/' target='_blank'>https://ssia.org.sg/</a></p>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card news-block'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-2 news-dates'>

                                                <h6>September 28</h6>
                                                <h6>2023,</h6>
                                                <h6>05.00 GMT</h6>
                                            </div>
                                            <div className='col-md-10 news-body'>
                                                <h4>Marquee Semiconductor Expands Presence in India with New Location at VSSUT Campus in Odisha</h4>
                                                
                                                <p className='text-justify'><span>MILPITAS, CALIFORNIA, UNITED STATES, September 27, 2023/<a href='https://www.einpresswire.com/shareable-preview/AB5dZPV2zwmXA27lCwJ0Eg' target='_blank'>EINPresswire.com</a>/</span> -- Marquee Semiconductor , a leading innovator in the semiconductor industry, is thrilled to announce the establishment of a new location at the Veer Surendra Sai University of Technology (VSSUT) campus, Burla, Odisha in India. This strategic move reinforces Marquee Semiconductor's commitment to advancing technology and fostering talent in the region. Moreover, this partnership is a significant step forward for Marquee as it establishes its primary “Center of Excellence in Chiplet Design and Systems”.</p>
                                                <p className='text-justify'>The inauguration of this facility marks a significant milestone in Marquee Semiconductor's journey, demonstrating its dedication to providing semiconductor solutions to India's thriving tech community. The proximity to VSSUT, a prestigious educational institution renowned for its engineering and technology programs, underscores Marquee's commitment to nurturing local talent and driving innovation by collaborating with the local community. Indeed, the company was able to hire top talents through a campus drive program after the official agreement.</p>
                                                <p className='text-justify'>Marquee Semiconductor's new facility at VSSUT will serve as a hub for research, development, and collaboration, providing a dynamic environment for engineers, researchers, and students to work closely on groundbreaking semiconductor projects. This facility will house advanced labs, testing equipment, and collaborative spaces, enabling Marquee Semiconductor to engage in cutting-edge research and development activities.</p>
                                                <p className='text-justify'>"We are excited to open this new facility at the Veer Surendra Sai University of Technology campus in India," said Don Smith, VP Engineering at Marquee Semiconductor. "This marks a significant step in our mission to foster innovation, empower local talent, and strengthen our presence in India. We look forward to working closely with the VSSUT community to drive technological advancements and contribute to India's growing semiconductor ecosystem."</p>
                                                <p className='text-justify'>The alliance between Marquee Semiconductor and VSSUT aims to provide students with valuable real-world experience, foster innovation, and support the development of new technologies. Through internships, research projects, and knowledge-sharing initiatives, Marquee Semiconductor will actively engage with the VSSUT community to promote learning and professional development.</p>
                                                <p className='text-justify'>“The collaboration with Marquee Semiconductor is significant, and will lead to a fruitful exchange of technology, resources, and ideas between academia and industry”; expressed Professor Banshidhar Majhi, the Vice Chancellor of VSSUT. “This will support VSSUT to play a pivotal role in building a semiconductor ecosystem in the region.”</p>
                                                <h6>About Veer Surendra Sai University of Technology (VSSUT)</h6>
                                                <p className='text-justify'>Veer Surendra Sai University of Technology, formerly known as the University College of Engineering, Burla, is a state university located in Burla, Sambalpur, Odisha, India. Established in 1956, it is the oldest engineering college in Odisha. UCE Burla, its former name, was officially changed to its current name on 10 June 2009, as a result of a resolution by the Government of Odisha to accord it with the status of a unitary university. The university was declared eligible to receive central assistance under Section 12B of the UGC Act in 2012.</p>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card news-block'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-2 news-dates'>

                                                <h6>September 07</h6>
                                                <h6>2023,</h6>
                                                <h6>05.00 GMT</h6>
                                            </div>
                                            <div className='col-md-10 news-body'>
                                                <h4>Marquee Semiconductor Achieves ISO 9001 Certification for Exemplary Management System</h4>
                                                
                                                <p className='text-justify'><span>MILPITAS, CALIFORNIA, UNITED STATES, September 6, 2023/<a href='https://www.einpresswire.com/shareable-preview/GFEBzuvYcTBbceoOZZfNjA' target='_blank'>EINPresswire.com</a>/</span> -- Marquee Semiconductor , a leading name in semiconductor chip solutions, proudly announces its successful attainment of the ISO 9001 certification in India at our R&D location in Bhubaneswar. This prestigious certification validates Marquee Semiconductor’s unwavering commitment to maintaining an exceptional quality management system that meets recognized standards.</p>
                                                <p className='text-justify'>ISO 9001 is a globally standard that signifies a company’s dedication to implementing and upholding stringent quality management practices across its Manufacturing of Semiconductor & Computer Programming, Consultancy processes. Marquee Semiconductor’s achievements of this certification underlines its dedication to delivering superior design services, consistently exceeding customer expectations, and continuously enhancing internal processes. The Certificate of Registration obtained by the company recognizes that the Management system of the company meets the world-class standards for its categories.</p>
                                                <p className='text-justify'>Among the factors that contributed to Marquee Semiconductor’s ISO 9001 certification includes the focus on two pillars: “Customer”, listen to your customers and design to their requirement; and “Execution”, a productive execution to deliver the customer product that exceeds the standard quality. These two pillars have kept Marquee to stay focus and develop internal processes to achieve this goal.</p>
                                                <p className='text-justify'>“The semiconductor industry is poised for significant expansion, highlighting the enduring role of semiconductors in our daily lives, where they serve as the backbone of various products and services. Marquee Semiconductor has consistently led in innovation and growth within this industry. With their latest ISO 9001 Certification, Marquee is well-positioned to further advance. We are proud to have Marquee as a member of GSA and we look forward to seeing their future success”, said Whitney Gallina, Director of Marketing at Global Semiconductor Alliance.</p>
                                                <p className='text-justify'>Purna Mohanty, CEO of Marquee Semiconductor, expressed his enthusiasm for achieving this milestone, stating “Obtaining the ISO 9001 certification for our India R&D in Bhubaneswar is a testament to our unrelenting commitment to quality and customer satisfaction. The company has invested significantly in bringing in quality management across board – Projects, Engineering, Recruitment and Human Resources areas that has resulted in highly productive project execution and customer satisfaction”. “This achievement reflects the hard work and dedication of our team and reflects the nature of the company by achieving the highest standards in their operations. With ISO 9001, we are well-equipped to consistently deliver reliable and high-performance design solutions to meet the evolving needs of the customers.”</p>
                                                <h6>About Marquee Semiconductor:</h6>
                                                <p className='text-justify'>As a global semiconductor services company headquartered in Silicon Valley, we specialize in connectivity-related designs for SoC/Chiplets and FPGAs. Our services range from staff augmentation to turnkey solutions, covering the complete journey from spec to silicon. Our global footprint enables us to serve clients worldwide, providing dependable results that drive innovation and expedite time to market. With a relentless pursuit of innovation, integrity, and transparency, Marquee Semiconductor has established itself as a go-to provider for cutting-edge design solutions. The company’s commitment to excellence is exemplified by its recent attainment of the ISO9001 certification, a recognition of its world-class Management System.</p>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card news-block'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-2 news-dates'>

                                                <h6>June 05</h6>
                                                <h6>2023,</h6>
                                                <h6>03.15 GMT</h6>
                                            </div>
                                            <div className='col-md-10 news-body'>
                                                <h4>Marquee Semiconductor Acquires Semikunn Technology Services, Expanding Design Services Portfolio</h4>
                                                
                                                <p className='text-justify'><span>MILPITAS, CALIFORNIA, UNITED STATES, June 4, 2023/EINPresswire.com/ -- Marquee Semiconductor</span> ,  a leading global provider of connectivity solutions for SoC/Chiplets and FPGAs, is pleased to announce the successful acquisition of Semikunn Technology Services, a prominent design services company based in India. This strategic move further strengthens Marquee Semiconductor's position as a comprehensive provider of end-to-end semiconductor solutions.</p>
                                                <p className='text-justify'>The acquisition of Semikunn Technology Services complements back Marquee Semiconductor's existing expertise and expands its design services portfolio. Semikunn Technology Services has established itself as a trusted name in the semiconductor industry, offering innovative design solutions across various domains. By combining the talents and resources of both companies, Marquee Semiconductor aims to deliver even greater value to its global customer base.</p>
                                                <p className='text-justify'>Purna Mohanty, CEO of Marquee Semiconductor, expressed enthusiasm about the acquisition, stating, "We are thrilled to welcome Semikunn Technology Services to the Marquee Semiconductor family. This strategic acquisition aligns perfectly with our growth objectives and strengthens our ability to provide comprehensive connectivity solutions to our customers. Semikunn's talented team and their expertise will play a crucial role in our ongoing commitment to innovation and customer satisfaction."</p>
                                                <p className='text-justify'>Jeetu Ranjan Kar, Founder of Semikunn Technology Services, also shared his thoughts on the acquisition, saying, "Joining forces with Marquee Semiconductor represents an exciting opportunity for the Semikunn team. Marquee's global reach, advanced automation systems, and commitment to excellence align perfectly with our values. Together, we will be well-positioned to deliver cutting-edge design services and further drive innovation in the semiconductor industry."</p>
                                                <p className='text-justify'>The integration of Semikunn Technology Services into Marquee Semiconductor's operations will be seamless, with a focus on preserving and enhancing customer relationships. The combined resources and expertise will enable the development of groundbreaking solutions that address the ever-evolving demands of the semiconductor market.</p>
                                                <h6>About Marquee Semiconductor:</h6>
                                                <p className='text-justify'>Marquee Semiconductor is a global company headquartered in Silicon Valley, specializing in connectivity solutions for custom SoC/Chiplets and FPGAs. With a strong focus on innovation, automation, and customer satisfaction, Marquee Semiconductor delivers end-to-end semiconductor solutions across diverse industry verticals. For more information, please visit <a href='https://marqueesemi.com/' target={'_blank'}>https://marqueesemi.com/</a>.</p>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                            
            </div>
        </div>


        
    </>
  )
}

export default News