//import { Tooltip } from 'bootstrap'
import React from 'react'
import {Link} from "react-router-dom";

function Events() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 Events-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Events</h1>
                        <p className='blockquote'>Handling the stress so your event is a success</p>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5  text-center'>
                    <h3 className='text-uppercase text-mcolor'>Events @ Marquee</h3>
                    <p>
                    Marquee Semiconductor provides extended support for many global events to exchange technology and innovations during leadership meetings. Engineers take part in conferences like DAC to discuss, deliberate, and explore into the frontiers of semiconductor eco-systems that could eventually enable new technologies and ideas.
                    </p>
                </div>
            </div>            
        </div>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                <div className=''>
                                    <p className='text-uppercase blockquote text-mcolor'>Symbiosys</p>
                                    <p className='text-justify'>
                                    MARQUEE is a member of the Symbiosys Alliance. The Symbiosys Alliance is an
                                    I/O interconnect technology group chartered to create value for its membership and
                                    for their respective customers by strategically and collaboratively aligning member
                                    products and services to current and upcoming market opportunities. More
                                    information can be found at <a href='https://www.symbiosys.io' target={"_blank"} >www.symbiosys.io.</a></p>
                                   {/* <button className='btn btn-outline-info'>Learn More</button> */}
                                </div>
                            </div>
                            <div className='col-md-6 p-3'>
                                <img src='./images/symbiosys-1.jpg' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid bg-light'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 p-3'>
                                <img src='./images/IMG_8889.jpg' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                            </div>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                <div className=''>
                                    <p className='text-uppercase blockquote text-mcolor'>DAC-2021</p>
                                    <p className='text-justify'>
                                    MARQUEE had exhibited the advanced engineering innovations by the team
                                    members in the Design Automation Conference (DAC 2021) which is recognized as
                                    one of the premier events for the design and design automation of electronic chips to
                                    systems. This opened up opportunities for designers, researchers, tool developers
                                    and vendors to create a global semiconductor ecosystem. This is an annual event
                                    and having many more sister conferences focusing AI/ML, FPGA, approximate
                                    computing and more. To know more about DAC, visit <a href='https://www.dac.com' target={"_blank"}>www.dac.com</a></p>
                                    {/*<button className='btn btn-outline-info'>Learn More</button>*/}
                                </div>
                                
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                <div className=''>
                                    <p className='text-uppercase blockquote text-mcolor'>GSA</p>
                                    <p className='text-justify'>
                                    Marquee Semiconductor Inc. takes the opportunity to become a member of Global
                                    Semiconductor Alliance (GSA) to meet other leaders and be part of the global
                                    community that will help sustain the company. You can find us at <a href='https://www.gsaglobal.org/membership/member-directory/' target={"_blank"} >https://www.gsaglobal.org/membership/member-directory/</a></p>
                                   {/* <button className='btn btn-outline-info'>Learn More</button> */}
                                </div>
                            </div>
                            <div className='col-md-6 p-3'>
                                <img src='./images/gsa.jpg' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid  bg-light'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 p-3'>
                                <img src='./images/career_sig.jpg' alt='Marquee Simiconductor' className='img-fluid rounded'/>
                            </div>
                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                <div className=''>
                                    <p className='text-uppercase blockquote text-mcolor'>NUS Postgraduate Career Fair 2022 – Singapore</p>
                                    <p className='text-justify'>
                                    We create platforms and opportunities for new engineers to innovate through
                                    involvement in cutting-edge technologies. During the Singapore career fair in 2022 we
                                    were able to reach out to 100+ fresh talents and inspire them to pursue a career in the
                                    growing semiconductor industry.</p>
                                    {/*<button className='btn btn-outline-info'>Learn More</button>*/}
                                </div>
                                
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Events