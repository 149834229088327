import React from 'react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

//import image1 from './images/banner2.jpg';

function Gallery() {

    const images = [
        {
          original: './images/gallery/gal_1.jpg',
          thumbnail: './images/gallery/gal_1.jpg',
        },
        {
          original: './images/gallery/gal_2.jpg',
          thumbnail: './images/gallery/gal_2.jpg',
        },
        {
            original: './images/gallery/gal_3.jpg',
            thumbnail: './images/gallery/gal_3.jpg',
        },
        {
            original: './images/gallery/gal_4.jpg',
            thumbnail: './images/gallery/gal_4.jpg',
        },
        {
            original: './images/gallery/gal_5.jpg',
            thumbnail: './images/gallery/gal_5.jpg',
        },
        {
            original: './images/gallery/gal_6.jpg',
            thumbnail: './images/gallery/gal_6.jpg',
        },
        {
            original: './images/gallery/gal_7.jpg',
            thumbnail: './images/gallery/gal_7.jpg',
        },
        {
            original: './images/gallery/gal_8.jpg',
            thumbnail: './images/gallery/gal_8.jpg',
        },
        {
            original: './images/gallery/gal_9.jpg',
            thumbnail: './images/gallery/gal_9.jpg',
        },
        {
            original: './images/gallery/gal_10.jpg',
            thumbnail: './images/gallery/gal_10.jpg',
        },
        {
            original: './images/gallery/gal_11.jpg',
            thumbnail: './images/gallery/gal_11.jpg',
        },
        {
            original: './images/gallery/gal_12.jpeg',
            thumbnail: './images/gallery/gal_12.jpeg',
        },
      ];

  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 Gallery-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Gallery</h1>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>Life @ Marquee</h3>
                    {/*<p>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” </p>*/}
                </div>
            </div>
            <div className='row '>
                <div className='col-md-12'>
                <ImageGallery items={images} autoPlay={true} />
                </div>
            </div>
            
                    
        </div>
        
    </>
  )
}

export default Gallery