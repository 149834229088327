import React from 'react'
import { useState } from "react";
import Swal from 'sweetalert2';

function Corplink() {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [message, setMessage] = useState("");


    const [errorMessage, setErrorMessage] = useState("");

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Submit");

    const contactSubmit = (e) =>{
        e.preventDefault();

        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter business email');
            return false;
        }
        else
        {
            setErrorMessage("");
        }

        //return false;

        setBtnDisabled(true)
          setBtnText("Submitting...")
          fetch('https://32xpq6wqp2.execute-api.ap-south-1.amazonaws.com/sendPSAMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "user_name": firstName+ " "+lastName,
                 "user_fname": firstName,
                 "user_lname": lastName,
                 "user_email": email,
                 "user_contactNo": contactNo,
                 "user_message": message,
            })
            }).then( response => {

                Swal.fire("Success", "Mail Sent Successfully", "success");


                setFirstName("");
                setLastName("");
                setEmail("");
                setContactNo("");
                setMessage("");
                setBtnDisabled(false)
                setBtnText("Download")

                document.getElementById("btnCloseModal").click();

                
                
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error sending file.", "error");
            });

          e.target.reset();
    }

  return (
    <>

<div className='container-fluid'>
            <div className="modal fade" id="PSAContactModalID" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-success">
                            <h5 className="modal-title text-white" id="">Contact</h5>
                            <button type="button" id='btnCloseModal' className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={contactSubmit}  enctype="multipart/form-data" method="post">
                                
                            
                                <div class="row">
                                    <div class="col mb-3">
                                        <input type="text" className="form-control" id="txtFName" placeholder="First Name" name="txtFName" 
                                        onChange={(e)=>{
                                            setFirstName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    <div class="col mb-3">
                                        <input type="text" className="form-control" id="txtLName" placeholder="Last Name" name="txtLName" 
                                        onChange={(e)=>{
                                            setLastName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col">
                                    <input type="email" className="form-control" id="txtEmail" placeholder="Business Email" name="txtEmail" 
                                        onChange={(e)=>{
                                            setEmail(e.target.value)
                                            setErrorMessage("")
                                        }}
                                        required/>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" className="form-control" id="txtContactNo" placeholder="Contact No." name="txtContactNo" 
                                        onChange={(e)=>{
                                            setContactNo(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                </div>
                                
                                <div className="mb-3 mt-3">
                                    <textarea className="form-control" placeholder='Enter reson to download the brochure' rows="3" id='txtComment'
                                    onChange={(e)=>{
                                        setMessage(e.target.value)
                                    }}
                                    required
                                    ></textarea>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        {
                                            errorMessage?<>
                                            <div className='alert alert-danger'>
                                            {"Error: "+ errorMessage}
                                            </div>
                                            </>:<></>
                                        }
                                    </div>
                                </div>
    

                                <div className='row'>
                                    <div className='col-md-4 offset-md-4'>
                                        <button type="submit" className="btn btn-block btn-success"
                                         disabled={btnDisabled}>{btnText}</button>
                                    </div>
                                </div>      
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 PSA-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Professional Service Automation</h1>
                        <p className='blockquote'>Bringing up the QUALITY. The unforgotten aspect of project management</p>
                    </div>                    
                </div>
            </div>
        </div>
        {/*
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>KEY Benifits</h3>
                    <p className='mt-4'>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” provider. The company is founded and advised by Semiconductor industry veterans, who are based in Silicon Valley USA for 25+ years, with leadership experience at many leading companies. The company focuses on 3 verticals: AI/ML implementation leveraging FPGAs and ACAP devices, Silicon/FPGA/ACAP based Digital Solution and Analog/Mixed Signal Solution. The company’s engineering strengths are in CNN / RNN, Algorithms / C++ / HLS, SystemVerilog/UVM, Digital – PCIe / DDR / DisplayPort / USB / SATA / CXL / CXS / Coherency / NVMe, Analog – Power Management / Data Converters.</p>
                    
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-md-4 text-center mt-5'>
                    <div className='drive-img'>
                        <img src='./images/domain.png' alt='Marquee Simiconductor' className=''/>
                    </div>
                    <div className='drive-text'>
                        <p className='blockquote text-mcolor mb-1 '>Domain Expertise</p>
                        <p >providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation.</p>
                    </div> 
                </div>
                <div className='col-md-4 text-center mt-5'>
                    <div className='drive-img'>
                        <img src='./images/r-d.png' alt='Marquee Simiconductor' className=''/>
                    </div>
                    <div className='drive-text'>
                        <p className='blockquote text-mcolor mb-1 '>R&D Enablement</p>
                        <p >Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                    </div> 
                </div>
                <div className='col-md-4 text-center mt-5'>
                    <div className='drive-img'>
                        <img src='./images/ip.png' alt='Marquee Simiconductor' className=''/>
                    </div>
                    <div className='drive-text'>
                        <p className='blockquote text-mcolor mb-1 '>IP-enabled Infrastructure</p>
                        <p >providing the best-in-class turnkey Spec to Silicon Validation with a differentiation of innovative methodologies.</p>
                    </div> 
                </div>
            </div>
        </div>*/}
        {/*
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5'>
                            <div className='col-md-12'>
                                
                                <p className='text-justify'>
                                Budgeting for your organization, scheduling for the team, planning for yourself and
                                encouraging reports for your customer are the KEY for project management –
                                Marquee’s corpLink.ai can run the projects successfully.  
                                </p>
                                <p>Register today to explore more.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='row'>
                                    
                                    <div className='col-md-3'>
                                        <h5>Business Call </h5>
                                        <p className='text-primary'>+63 9171290247</p>
                                        <p className='text-primary'>+91 9861762523</p>
                                    </div>
                                    <div className='col-md-3'>
                                        <h5>Contact Sales</h5>
                                        <p className='text-primary cursor-pointer' data-bs-toggle="modal" data-bs-target="#PSAContactModalID">sales@marqueesemi.com</p>
                                        <p className='text-primary cursor-pointer' data-bs-toggle="modal" data-bs-target="#PSAContactModalID">sales@corplink.ai</p>
                                    </div>
                                    <div className='col-md-3'>
                                        <h5>Product Website</h5>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        */}

        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5'>
                            <div className='col-md-12'>
                                <div class="card">
                                    <div class="card-body">
                                        <img src="../images/corplink/psa-icon.png" className="img-fluid me-1" alt="" width={"50px"}/>
                                        <img src="../images/corplink/newlogo.png" className="img-fluid me-5" alt="" width={"200px"}/>
                                        <a className='btn btn-primary' href='https://corplink.ai' target='_blank' style={{textDecoration:"none"}}><i className='bi bi-box-arrow-up-right'></i> Visit Website</a>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid pt-5 pb-0'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h5 className='fw-bold text-uppercase text-mcolor pb-4'>Project Management</h5>
                            </div>
                            <div className='col-md-12 pb-2'>
                                <p>corpLink.ai provides a highly calibre project management tool to run a project successfully by</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='services'>
                                    <div className='row'>
                                        <div className="col-md-2 pb-5">
                                            <div className="service-item">
                                                <div className="img">
                                                    <img src="../images/psa/1.png" className="img-fluid" alt=""/>
                                                </div>

                                                <div className="details position-relative">
                                                    <div className="icon">
                                                    <i className="bi bi-bar-chart-steps"></i>
                                                    </div>
                                                    
                                                    <h6>N-Levels for Task breakdown</h6>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 pb-5">
                                            <div className="service-item">
                                                <div className="img">
                                                    <img src="../images/psa/2.png" className="img-fluid" alt=""/>
                                                </div>
                                                <div className="details position-relative">
                                                    <div className="icon">
                                                    <i className="bi bi-file-bar-graph"></i>
                                                    </div>
                                                    
                                                    <h6>Engineering status and Progress</h6>
                                                
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 pb-5">
                                            <div className="service-item">
                                                <div className="img">
                                                    <img src="../images/psa/3.png" className="img-fluid" alt=""/>
                                                </div>
                                                <div className="details position-relative">
                                                    <div className="icon">
                                                    <i className="bi bi-calendar4-week"></i>
                                                    </div>
                                                    
                                                    <h6>MoM and Action Requests Follow-up</h6>
                                                
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 pb-5">
                                            <div className="service-item">
                                                <div className="img">
                                                    <img src="../images/psa/4.png" className="img-fluid" alt=""/>
                                                </div>
                                                <div className="details position-relative">
                                                    <div className="icon">
                                                    <i className="bi bi-ticket"></i>
                                                    </div>
                                                    
                                                    <h6>Integrated Ticketing and Follow-up</h6>
                                                
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 pb-5">
                                            <div className="service-item">
                                                <div className="img">
                                                    <img src="../images/psa/5.png" className="img-fluid" alt=""/>
                                                </div>
                                                <div className="details position-relative">
                                                    <div className="icon">
                                                    <i className="bi bi-activity"></i>
                                                    </div>
                                                    
                                                    <h6>Quality, Cost and Deliverable (QCD) Reports</h6>
                                                
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 pb-5">
                                            <div className="service-item">
                                                <div className="img">
                                                    <img src="../images/psa/6.png" className="img-fluid" alt=""/>
                                                </div>
                                                <div className="details position-relative">
                                                    <div className="icon">
                                                        <i className="bi bi-display"></i>
                                                    </div>
                                                    
                                                    <h6>Isolated Customer Interfacing </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid pb-0'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 pb-5'>
                                <h5 className='fw-bold text-uppercase text-mcolor'>Key Features</h5>
                            </div>
                            {/*<div className='col-md-12'>
                                <img src='./images/psa2.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
            </div>*/}

                            <div className='col-md-12'>
                                <div className='psa-keyFeature'>
                                    <div className='row'>
                                        <div className='col-md-3 pb-5'>
                                            <div className='service-item'>
                                                <img src='./images/psa/modular.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                                                <h3>Modular</h3>
                                            </div>
                                        </div>

                                        <div className='col-md-3 pb-5'>
                                            <div className='service-item'>
                                                <img src='./images/psa/encryption.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                                                <h3>Encryption</h3>
                                            </div>
                                        </div>

                                        <div className='col-md-3  pb-5'>
                                            <div className='service-item'>
                                                <img src='./images/psa/email.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                                                <h3>Auto Email</h3>
                                            </div>
                                        </div>

                                        <div className='col-md-3  pb-5'>
                                            <div className='service-item'>
                                                <img src='./images/psa/access.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                                                <h3>Access Control</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid  pb-0'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 pb-5'>
                                <h5 className='fw-bold text-uppercase text-mcolor'>Products at a Glance</h5>
                            </div>
                            
                            
                            {/*<div className='col-md-12'>
                                <img src='./images/psa3.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                            </div>*/}

                            <div className='col-md-12'>
                                <div className='featured-services'>
                                    <div className='row'>
                                        <div class="col-md-4 d-flex mb-5">
                                            <div class="service-item position-relative">
                                                <div class="icon"><i class="bi bi-layers-half icon"></i></div>
                                                <h4>Manage Project</h4>
                                                <p>Maintaining deliverables and task execution, live data and Report analysis, Resource reallocation.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-4 d-flex mb-5">
                                            <div class="service-item position-relative">
                                                <div class="icon"><i class="bi bi-ticket icon"></i></div>
                                                <h4>Ticketing</h4>
                                                <p>Raise tickets, follow-up, forwarding and added watcher list for complete track.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-4 d-flex mb-5">
                                            <div class="service-item position-relative">
                                                <div class="icon"><i class="bi bi-calendar4-week icon"></i></div>
                                                <h4>Minutes of Meeting</h4>
                                                <p>Adding MoM details, action requests and status follow-up, internal and external meeting group and email.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-4 d-flex mb-5">
                                            <div class="service-item position-relative">
                                                <div class="icon"><i class="bi bi-journals icon"></i></div>
                                                <h4>Document Management</h4>
                                                <p>Managing documents, controlled access permission.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-4 d-flex mb-5">
                                            <div class="service-item position-relative">
                                                <div class="icon"><i class="bi bi-cash-coin icon"></i></div>
                                                <h4>Sales</h4>
                                                <p>Initiating sales for existing and new customers, project requirements and cost estimation.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-4 d-flex mb-5">
                                            <div class="service-item position-relative">
                                                <div class="icon"><i class="bi bi-collection icon"></i></div>
                                                <h4>Inventory</h4>
                                                <p>Managing assets, licenses and RFQ, Customers and NDAs.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-4 d-flex mb-5">
                                            <div class="service-item position-relative">
                                                <div class="icon"><i class="bi bi-people icon"></i></div>
                                                <h4>Hiring</h4>
                                                <p>Initiating enrolment process, interviewer feedbacks and approval process for offer release.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-4 d-flex mb-5">
                                            <div class="service-item position-relative">
                                                <div class="icon"><i class="bi bi-person-badge icon"></i></div>
                                                <h4>HR System</h4>
                                                <p>Employee information, Policies, attendance, Employee Connect Program (ECP) and Off-boarding process.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-4 d-flex mb-5">
                                            <div class="service-item position-relative">
                                                <div class="icon"><i class="bi bi-window-sidebar icon"></i></div>
                                                <h4>Customer Portal</h4>
                                                <p>Customer access for status and QCD Reports</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Corplink