import React from 'react'
import Sendquery from './Sendquery'

function Locations() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 Locations-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Contact</h1>
                        {/*<p className='blockquote'>Achieves Best-In-Class Power Performance, Area, Schedule</p>*/}
                    </div>                    
                </div>
            </div>
        </div>

        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5 mb-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>Our Offices</h3>
                    {/*<p>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” </p>*/}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='card p-2 mt-3 location-card'>
                    <img src="./images/usa-location.jpg" alt="Marquee Semiconductor" className="img-fluid rounded"/>
                    <span className='text-uppercase text-mcolor pt-3 mb-1'><img src='./images/usa.png' alt='Marquee Simiconductor Logo' className='flag'/> USA</span>
                        <p className='mb-0'>
                            Marquee Semiconductor Inc.<br/>
                            500 E Calaveras Blvd. Suite 218, Milpitas CA 95035, USA
                        </p>
                    </div>                    
                </div>
                <div className='col-md-4'>
                    <div className='card p-2 mt-3 location-card'>
                    <img src="./images/lingaraj.jpg" alt="Marquee Semiconductor" className="img-fluid rounded"/>
                    <span className='text-uppercase text-mcolor pt-3 mb-1'><img src='./images/india.png' alt='Marquee Simiconductor Logo' className='flag'/> INDIA</span>
                        <p className='mb-0'>
                            
                            Marqueesemi India Pvt. Ltd.<br/>
                            327, 3rd Floor, DLF Cyber City, Patia, Bhubaneswar, Odisha, India 751024
                        </p>
                        <hr/>
                        <p className='mb-0'>
                            
                            VSSUT Burla<br/>
                            Sambalpur, Odisha, India. PIN:768018
                        </p>
                    </div>                    
                </div>
                <div className='col-md-4 '>
                    <div className='card p-2 mt-3 location-card'>
                    <img src="./images/manila-location.jpg" alt="Marquee Semiconductor" className="img-fluid rounded"/>
                    <span className='text-uppercase text-mcolor pt-3 mb-1'><img src='./images/Philippines.png' alt='Marquee Simiconductor Logo' className='flag'/>  PHILIPPINES</span>
                        <p className='mb-0'>
                            Marquee BPO Inc.<br/>
                            Level 10-1 One Global Place, 25th Street & 5th Avenue Bonifacio Global City, Taguig City, 1634 Philippines
                            <br/><br/>

                            **26th Floor The Podium, Lower, Ortigas Center, Mandaluyong, 1550 Philippines
                        </p>
                    </div>                    
                </div>
                <div className='col-md-4'>
                    <div className='card p-2 mt-3 location-card'>
                    <img src="./images/singapore-location.jpg" alt="Marquee Semiconductor" className="img-fluid rounded"/>
                    <span className='text-uppercase text-mcolor pt-3 mb-1'><img src='./images/Singapore.png' alt='Marquee Simiconductor Logo' className='flag'/>  SINGAPORE</span>
                    <p className='mb-0'>Marquee Semiconductor Singapore Pte Ltd<br/>
                                LaunchPad,
                                Block 71 #07-08,
                                Ayer Rajah Crescent,<br/>
                                Singapore, 139951</p>
                    </div>                    
                </div>
                <div className='col-md-4'>
                    <div className='card p-2 mt-3 location-card'>
                    <img src="./images/malaysia-location.png" alt="Marquee Semiconductor" className="img-fluid rounded"/>
                    <span className='text-uppercase text-mcolor pt-3 mb-1'><img src='./images/malaysia.png' alt='Marquee Simiconductor Logo' className='flag'/>  MALAYSIA</span>
                    <p className='mb-0'>Marquee Semiconductor (M) Sdn.<br/>
                                Bhd No. 8, Lorong Pulau Tikus 10350 George Town Pulau Pinang, Malaysia
                                </p>
                    </div>                    
                </div>

            </div>
            <div className='row'>
                <div className='col-md-12 mt-5 pt-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>SEND YOUR QUERY</h3>
                    {/*<p>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” </p>*/}
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-md-6 d-flex align-items-center justify-content-center'>
                    <div>
                        <p className='blockquote text-mcolor'><i class="bi bi-envelope text-primary me-2"></i> sales@marqueesemi.com</p>
                        <p className='blockquote text-mcolor'><i class="bi bi-telephone text-primary me-2"></i> +1 (408) 717-3174</p>
                    </div>
                </div>
                <div className='col-md-6 p-3'>
                    <Sendquery/>
                </div>
            </div>
        </div>
    </>
  )
}

export default Locations