import React from 'react'

import { useState } from "react";
import Swal from 'sweetalert2';

function LeadershipThought() {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [message, setMessage] = useState("");

    //const [otp, setOTP] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Download");
    //const [otpBoxEnable, setOtpBoxEnable] = useState(false);
    //const [isMailVerified, setIsMailVerified] = useState(false);

    const [downloadFileName, setDownloadFileName] = useState("");

    const downloadPageSubmit = (e) =>{
        e.preventDefault();

        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter business email');
            return false;
        }
        else
        {
            setErrorMessage("");
        }

        //return false;

        setBtnDisabled(true)
          setBtnText("Downloading...")
          fetch('https://j00u65xk8f.execute-api.ap-south-1.amazonaws.com/sendDownloadBrochureMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "user_name": firstName+ " "+lastName,
                 "user_fname": firstName,
                 "user_lname": lastName,
                 "user_email": email,
                 "user_contactNo": contactNo,
                 "user_message": message,
                 "downloadType":`Leadership Thought ${downloadFileName}`,
            })
            }).then( response => {

                //Swal.fire("Success", "Mail Sent Successfully", "success");

                fetch(`/downloads/${downloadFileName}.pdf`).then(response => {
                    response.blob().then(blob => {
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = `${downloadFileName}.pdf`;
                        alink.click();
                    })
                })

                setFirstName("");
                setLastName("");
                setEmail("");
                setContactNo("");
                setMessage("");
                setBtnDisabled(false)
                setBtnText("Download")

                document.getElementById("btnCloseModal").click();

                
                
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error downloading file.", "error");
            });

          e.target.reset();
    }

  return (
    <>

        <div className='container-fluid'>
            <div className="modal fade" id="downloadModalID" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-success">
                            <h5 className="modal-title text-white" id="">Download Thought Leadership</h5>
                            <button type="button" id='btnCloseModal' className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={downloadPageSubmit}  enctype="multipart/form-data" method="post">
                                
                            
                                <div class="row">
                                    <div class="col mb-3">
                                        <input type="text" className="form-control" id="txtFName" placeholder="First Name" name="txtFName" 
                                        onChange={(e)=>{
                                            setFirstName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    <div class="col mb-3">
                                        <input type="text" className="form-control" id="txtLName" placeholder="Last Name" name="txtLName" 
                                        onChange={(e)=>{
                                            setLastName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col">
                                    <input type="email" className="form-control" id="txtEmail" placeholder="Business Email" name="txtEmail" 
                                        onChange={(e)=>{
                                            setEmail(e.target.value)
                                            setErrorMessage("")
                                        }}
                                        //disabled={isMailVerified}
                                        required/>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" className="form-control" id="txtContactNo" placeholder="Contact No." name="txtContactNo" 
                                        onChange={(e)=>{
                                            setContactNo(e.target.value)
                                        }}
                                        required/>
                                    </div>

                                </div>
                                
                                
                                
                                <div className="mb-3 mt-3">
                                    <textarea className="form-control" placeholder='Enter reson to download the leadership thought' rows="3" id='txtComment'
                                    onChange={(e)=>{
                                        setMessage(e.target.value)
                                    }}
                                    required
                                    ></textarea>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        {
                                            errorMessage?<>
                                            <div className='alert alert-danger'>
                                            {"Error: "+ errorMessage}
                                            </div>
                                            </>:<></>
                                        }
                                    </div>
                                </div>
    

                                <div className='row'>
                                    <div className='col-md-4 offset-md-4'>
                                        <button type="submit" className="btn btn-block btn-success"
                                         disabled={btnDisabled}>{btnText}</button>
                                    </div>
                                </div>      
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 LeadershipThought-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Marquee Views</h1>
                        <p className='blockquote text-info d-none'>Achieves Best-In-Class Power Performance, Area, Schedule</p>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
           {/*
            <div className='row'>
                <div className='col-md-12 mt-5 mb-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>Downloads</h3>
                    <p>
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                    </p>
                </div>
            </div> */}
            {/*
            <div className='row'>
                <div className='col-md-12'>
                <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Tittle</th>
                                <th scope="col">Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>providing the best-in-class turnkey end-to-end SoC solution</td>
                                <td><Link to="/downloads/Marquee.pdf" className='btn btn-sm btn-success' target="_blank" download><i class="bi bi-download"></i>  Download</Link>
                                
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>*/}
        </div>

        <div className='container-fluid pt-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card newCard'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div>
                                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Serial Link for Chiplet-to-Chiplet Data Communication [v1.01.25]</span></p>
                                                    <p className='text-justify'>This paper presents the survey for UCIe and BoW for die-to-die interconnect. The most 
                                                    significant benefits of UCIe and BoW are explored and the advantages one over another is 
                                                    highlighted that supports compute scaling by utilizing streaming (user-defined) protocols to 
                                                    establish low-latency connections between the Network-on-a-Chips (NoCs) of various server 
                                                    (or AI) dies in a single package and/or with the flexibility, and enabling heterogeneous 
                                                    integration.
                                                    </p>
                                                    <h6><span className='pe-2 text-mcolor'><i className="bi bi-person-bounding-box"></i></span> Nijwam Wary, <small>IIT Bhubaneswar</small></h6>
                                                    <h6><span className='pe-2 text-mcolor'><i className="bi bi-person-bounding-box"></i></span> Mukesh Kumar Sukla, <small>Marquee Semiconductor Inc.</small></h6>
                                                    <h6><span className='pe-2 text-mcolor'><i className="bi bi-person-bounding-box"></i></span> David Ratchkov, <small>Thrace Systems</small></h6>
                                                    
                                                    
                                                </div>
                                                
                                            </div>
                                            <div className='col-md-12 pt-5'>
                                                    <button type="button" className='btn btn-success' 
                                                    onClick={(e)=>{
                                                        setDownloadFileName("WPD-23-0002-v1.01.25")
                                                    }}
                                                    data-bs-toggle="modal" data-bs-target="#downloadModalID"><i class="bi bi-download"></i>  Download</button>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid pt-5 pb-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card newCard'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div>
                                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Serial Link for Chiplet-to-Chiplet Data Communication [v1.01.02]</span></p>
                                                    <p className='text-justify'>This paper presents the survey for UCIe and BoW for die-to-die interconnect. The most 
                                                    significant benefits of UCIe and BoW are explored and the advantages one over another is 
                                                    highlighted that supports compute scaling by utilizing streaming (user-defined) protocols to 
                                                    establish low-latency connections between the Network-on-a-Chips (NoCs) of various server 
                                                    (or AI) dies in a single package and/or with the flexibility, and enabling heterogeneous 
                                                    integration.
                                                    </p>
                                                    <h6><span className='pe-2 text-mcolor'><i className="bi bi-person-bounding-box"></i></span> Nijwam Wary, <small>IIT Bhubaneswar</small></h6>
                                                    <h6><span className='pe-2 text-mcolor'><i className="bi bi-person-bounding-box"></i></span> Mukesh Kumar Sukla, <small>Marquee Semiconductor Inc.</small></h6>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div className='col-md-12 pt-5'>
                                                <button type="button" className='btn btn-success' 
                                                    onClick={(e)=>{
                                                        setDownloadFileName("WPD-23-0001-v1.01.25")
                                                    }}
                                                    data-bs-toggle="modal" data-bs-target="#downloadModalID"><i class="bi bi-download"></i>  Download</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        
    </>
  )
}

export default LeadershipThought