import React from 'react'

function Partnerships() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 Partnership-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Partnerships</h1>
                        <p className='blockquote'>"Alone we can do so little; together we can do so much." — Helen Keller</p>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5 mb-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>CURRENT ECOSYSTEM PARTNERSHIPS</h3>
                    <p>
                    Marquee Semiconductor trusts in partnership to grow fast and makes the partners to grow faster being a crucial ingredient.
                    </p>
                </div>
            </div>
            {/* <div className='row mt-5'>
                <div className='col-md-7 d-flex align-items-center justify-content-center'>
                    <div>
                        <p className='text-uppercase blockquote text-mcolor'>CURRENT ECOSYSTEM PARTNERSHIPS</p>
                        <p>
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                        </p>
                    </div>
                </div>
                <div className='col-md-5'>
                    <div className='row'>
                        <div className='col-md-6 p-1'>
                            <div className='card'>
                                <img src="./images/xilinx.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                            </div>                                                        
                        </div>
                        <div className='col-md-6 p-1'>
                            <div className='card'>
                                <img src="./images/cadence.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>
                            </div>                            
                        </div>
                        <div className='col-md-6 p-1'>
                            <div className='card'>
                                <img src="./images/avery.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                            </div>                            
                        </div>
                        <div className='col-md-6 p-1'>
                            <div className='card'>
                                <img src="./images/deepedge.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>
                            </div>                            
                        </div>
                        <div className='col-md-6 p-1'>
                            <div className='card'>
                                <img src="./images/hrx.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>
                            </div>                            
                        </div>
                        <div className='col-md-6 p-1'>
                            <div className='card'>
                                <img src="./images/mentor.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                            </div>                            
                        </div>
                        <div className='col-md-6 p-1'>
                            <div className='card'>
                                <img src="./images/plda.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                            </div>                            
                        </div>
                        <div className='col-md-6 p-1'>
                            <div className='card'>
                                <img src="./images/signatureip.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                            </div>                            
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
        <div className='container-fluid'>
        <div className='row bg-light'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5 mb-5'>
                            <div className='col-md-4 d-flex align-items-center justify-content-center'>
                                <div className='card bg-light'>
                                    <img src="./images/partner1.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                                </div> 
                            </div>
                            <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase blockquote text-mcolor mt-2'>Signature IP</p>
                                    <p>
                                    We are a technology solution partner to Signature IP for setting up a world-class IP solution for both Coherent and Non-Coherent NoC.
                                    </p>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5 mb-5'>                            
                            <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase blockquote text-mcolor mt-2'>Avery</p>
                                    <p>
                                    Marquee Semiconductor is a long-term partner to Avery Design Systems to accelerate SoC level designs using Avery IP platforms.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 d-flex align-items-center justify-content-center'>
                                <div className='card'>
                                    <img src="./images/avery.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                                </div> 
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>

            <div className='row bg-light'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5 mb-5'>  
                            <div className='col-md-4 d-flex align-items-center justify-content-center'>
                                <div className='card bg-light'>
                                    <img src="./images/mentor.png" alt="Marquee Semiconductor" className="img-fluid p-3" />    
                                </div> 
                            </div>                          
                            <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase blockquote text-mcolor mt-2'>Mentor</p>
                                    <p>
                                    Marquee Semiconductor uses Mentor’s EDA platform to provide best-in-class methodology to our customers.
                                    </p>
                                </div>
                            </div>
                            
                        </div>                    
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5 mb-5'>
                        <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase blockquote text-mcolor mt-2'>AMD</p>
                                    <p>
                                    Marquee Semiconductor is a certified member of AMD Adaptive Computing Partner and encouraging engineers to upskill their domain knowledge in the process for value chain for internal process and customers. 
                                    </p>
                                    {/*<a className='btn btn-outline-info' href='https://www.xilinx.com/xilinx-partner-program.html' target={"_blank"}>Learn More</a>*/}
                                </div>
                            </div>
                            <div className='col-md-4 d-flex align-items-center justify-content-center'>
                                <div className='card'>
                                    <img src="./images/AMD_Adaptive_Computing_Partner_Badge_SelectCert.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                                </div> 
                            </div>
                            
                        </div>                    
                    </div>
                </div>
            </div>

            <div className='row bg-light'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5 mb-5'>                            
                            
                            <div className='col-md-4 d-flex align-items-center justify-content-center'>
                                <div className='card bg-light'>
                                    <img src="./images/partner3.png" alt="Marquee Semiconductor" className="img-fluid p-3" />    
                                </div> 
                            </div>
                            <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase blockquote text-mcolor mt-2'>Ellisys</p>
                                    <p>
                                    We are connected with Ellisys through Symbiosys for protocol test and standard compliance solutions.
                                    </p>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>

            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5 mb-5'>       
                            
                        <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase blockquote text-mcolor mt-2'>SerialTek</p>
                                    <p>
                                    We are connected with SerialTek through Symbiosys for protocol test and standard compliance solutions.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 d-flex align-items-center justify-content-center'>
                                <div className='card '>
                                    <img src="./images/SerialTek-Logo.png" alt="Marquee Semiconductor" className="img-fluid p-3" />    
                                </div> 
                            </div>

                            
                            
                        </div>                    
                    </div>
                </div>
            </div>

            <div className='row bg-light'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5 mb-5'> 
                            
                            <div className='col-md-4 d-flex align-items-center justify-content-center'>
                                <div className='card bg-light'>
                                    <img src="./images/cadence.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                                </div> 
                            </div>
                            <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase blockquote text-mcolor mt-2'>CADENCE</p>
                                    <p>
                                    Our partnership with CADENCE established an advanced and unique process to be the leader of many areas of silicon-based design and solutions.
                                    </p>
                                </div>
                            </div>
                            
                        </div>                    
                    </div>
                </div>
            </div>

            

            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row mt-5 mb-5'> 

                            
                        <div className='col-md-8 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase blockquote text-mcolor mt-2'>Synopsys</p>
                                    <p>
                                    Marquee Semiconductor uses SYNOPSYS tools and VIPs to bring unique solutions for customer driven projects.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 d-flex align-items-center justify-content-center'>
                                <div className='card'>
                                    <img src="./images/synopsys.png" alt="Marquee Semiconductor" className="img-fluid p-3"/>    
                                </div> 
                            </div>

                            

                            
                            
                        </div>                    
                    </div>
                </div>
            </div>

                       
        </div>
    </>
  )
}

export default Partnerships