import React from 'react'
import {Link} from 'react-router-dom'

function GSA() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 downloads-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6 text-mcolor'>GSA</h1>
                        <p className='blockquote text-info d-none'>Achieves Best-In-Class Power Performance, Area, Schedule</p>
                    </div>                    
                </div>
            </div>
        </div>
    
        <div className='container-fluid p-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <img src='../images/certificate-2.jpg' alt='Marquee Simiconductor' className='img-fluid'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default GSA