import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
    const currentYear = new Date().getFullYear();
  return (
    <>
        <div className='container-fluid bg-mcolor footerbg' >
            <div className='row'>
                <div className='col-md-12 pt-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <img src='./images/marquee-white.png' alt='Marquee Simiconductor Logo' className='img-fluid footer-logo p-2'/>
                                <ul className='list-group list-group-horizontal socialicon mt-3'>
                                    <li className='list-group-item bg-transparent'><i class="bi bi-facebook"></i></li>
                                    <li className='list-group-item bg-transparent'><i class="bi bi-twitter"></i></li>
                                    <li className='list-group-item bg-transparent'><a href="https://www.linkedin.com/company/marquee-semiconductor-inc/" target="_blank"><i class="bi bi-linkedin"></i></a></li>
                                    <li className='list-group-item bg-transparent'><i class="bi bi-instagram"></i></li>
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <ul className='footer-nav'><p>Our Company</p>
                                    <li><NavLink to="/About">About Us</NavLink></li>
                                    <li><NavLink to="/Leadership">Leadership</NavLink> </li>
                                    <li><NavLink to="/Partnerships">Partnerships</NavLink></li>
                                    <li><NavLink to="/Membership">Membership</NavLink></li>
                                    {/*<li><NavLink to="/Downloads">Testimonials</NavLink></li>*/}

                                    <li><NavLink to="/Career">Careers</NavLink> </li>
                                    
                                    <li><NavLink to="/Locations">Contact</NavLink></li>
                                                                  
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <ul className='footer-nav'><p>Services</p>
                                    <li><NavLink to="/Digital">Digital</NavLink></li>
                                    <li><NavLink to="/Analog">Analog & Mixed-Signal</NavLink></li>
                                    <li><NavLink to="/Fpga">SW-FPGA</NavLink></li>
                                </ul>
                                <ul className='footer-nav'><p>Tools</p>
                                    <li><NavLink to="/Products">Project Management</NavLink></li>
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <ul className='footer-nav'><p>Solutions</p>
                                    <li><NavLink to="/AIML">AI/ML</NavLink></li>
                                    <li><NavLink to="/Storage">Storage</NavLink></li>
                                    <li><NavLink to="/AnalogMixedSignal">Analog & Mixed Signal</NavLink></li>
                                </ul>

                                <ul className='footer-nav'><p>Insights</p>
                                    <li><NavLink to="/ThoughtLeadership">Thought Leadership</NavLink></li>
                                    <li><NavLink to="/Events">Events</NavLink></li>
                                    <li><NavLink to="/Gallery">Gallery</NavLink></li>     
                                    <li><NavLink to="/Downloads">Downloads</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12 p-4 pb-1'>
                    <div className='container'>
                    <hr className='text-white bg-white'/>
                        <div className='row pt-5 pb-5 text-white footercontact'>
                            <div className='col-md-12 pb-2'><h4 className='text-uppercase blockquote'><i class="bi bi-geo-alt-fill"></i> Contact</h4></div>                            
                            <div className='col-md-4'>
                                <p><img src='./images/usa.png' alt='Marquee Simiconductor Logo'/> USA</p>
                                <p>Marquee Semiconductor Inc.<br/>
                                500 E Calaveras Blvd. Suite 218, Milpitas CA 95035, USA</p>
                            </div>
                            <div className='col-md-4'>
                                <p><img src='./images/india.png' alt='Marquee Simiconductor Logo'/> INDIA</p>
                                <p>Marqueesemi India Pvt. Ltd.<br/>
                                    327, 3rd Floor, DLF Cyber City, Patia, Bhubaneswar, Odisha, India 751024</p>
                                    <hr/>
                                    <p>VSSUT Burla<br/>
                                Sambalpur, Odisha, India. PIN:768018</p>
                            </div>  
                            <div className='col-md-4'>
                                <p><img src='./images/philippines.png' alt='Marquee Simiconductor Logo'/> PHILIPPINES</p>
                                <p>Marquee BPO Inc.<br/>
                                Level 10-1 One Global Place, 25th Street & 5th Avenue Bonifacio Global City, Taguig City, 1634 Philippines</p>
                                <p>**26th Floor The Podium, Lower, Ortigas Center, Mandaluyong, 1550 Philippines</p>
                            </div> 
                            <div className='col-md-4'>
                                <p><img src='./images/singapore.png' alt='Marquee Simiconductor Logo'/> SINGAPORE</p>
                                <p>Marquee Semiconductor Singapore Pte Ltd<br/>
                                LaunchPad,
                                Block 71 #07-08,
                                Ayer Rajah Crescent,<br/>
                                Singapore, 139951</p>
                            </div>
                            <div className='col-md-4'>
                                <p><img src='./images/malaysia.png' alt='Marquee Simiconductor Logo'/> MALAYSIA</p>
                                <p>Marquee Semiconductor (M) Sdn.<br/>
                                Bhd No. 8, Lorong Pulau Tikus 10350 George Town Pulau Pinang,<br/> Malaysia
                                </p>
                            </div> 
                            
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className='container-fluid bg-heighlight'>
            <div className='row'>
                <div className='col-md-12 p-2 text-center'>
                    <cite className=''>Copyright {currentYear} © Marquee Semiconductor Inc.</cite>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footer