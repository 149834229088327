import React from 'react'

function PagenotFound() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 pnf-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Oops! 404 Not Found</h1>
                        <p className='blockquote'>Sorry, an error has occured, Requested page not found!</p>
                    </div>                    
                </div>
            </div>
        </div>
    </>
  )
}

export default PagenotFound