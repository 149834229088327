import React, { useRef, useState, useEffect } from 'react'
import Swal from 'sweetalert2';
// import emailjs from '@emailjs/browser';


function DownloadProductInfo(props) {
    const form = useRef();

    const productName = props.productName;
    const produtFileName = props.produtFileName;
    const produtFileDownloadName = props.produtFileDownloadName;

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [companyName, setCompanyName] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Request");

    const [base64Attachment, setBase64Attachment] = useState("");

    useEffect(() => {
        fetch(`/downloads/${produtFileName}`).then(response => {
            response.blob().then(blob => {

                let reader = new FileReader();
                reader.readAsDataURL(blob);

                reader.onload = () =>{
                    setBase64Attachment(reader.result);
                }
            })
        })
    }, [produtFileName]);


    
    function downloadProductFeature(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        
        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter business email');
            return false;
        }
        else
        {
            setErrorMessage("");
        }

        setBtnDisabled(true)
          setBtnText("Requesting...")
          fetch('https://t4dgil48g0.execute-api.ap-south-1.amazonaws.com/downloadProductMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "user_name": firstName+ " "+lastName,
                 "user_fname": firstName,
                 "user_lname": lastName,
                 "user_email": email,
                 "user_subject": subject,
                 "user_message": message,
                 "job_title": jobTitle,
                 "company_name": companyName,
                 "downloadType":productName,
                 "base64File":base64Attachment,
                 "FileName":produtFileDownloadName,
            })
            }).then( response => {

                /*fetch(`/downloads/${produtFileName}`).then(response => {
                    response.blob().then(blob => {
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = produtFileDownloadName;
                        alink.click();
                    })
                })*/

                setFirstName("");
                setLastName("");
                setEmail("");
                setSubject("");
                setMessage("");
                setCompanyName("");
                setJobTitle("");
                setBase64Attachment("");
                setBtnDisabled(false)
                setBtnText("Request")

                Swal.fire("Success", "Thank you for contacting us! The Product Brief is attached with an email.", "success");
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Request")
                Swal.fire("Error", "Error sending mail.", "error");
            });

          e.target.reset();
    }

    //   const changehandler = (e)=>{
    //     const inputField = e.target.name;
    //     if(inputField=='user_name'){
    //         setenqname(e.target.value);
    //         console.log(setenqname);
    //         if(!e.target.value){
    //             setenqnameerror("fill your name")
    //         }else if(e.target.value.length<4){
    //             setenqnameerror("Atleast 4 Character")
    //         }else{
    //             setenqnameerror("")
    //         }
    //     }        
    //   }
    
  return (
    <>

        <div className='container-fluid'>
            <div className="modal fade" id="downloadProductFeatureModalID" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header  modalHead-bg">
                            <h5 className="modal-title" id="">Request Product Brief</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <form ref={form} onSubmit={downloadProductFeature}>

                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <input type="text" className="form-control " id="fname" placeholder="First Name *" name="firstName" 
                                        onChange={(e)=>{
                                            setFirstName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    <div className='col-md-6  mb-2'>
                                        <input type="text" className="form-control " id="lname" placeholder="Last Name *" name="lastName" 
                                        onChange={(e)=>{
                                            setLastName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                            <input type="email" className="form-control" name="user_email" id="user_email" placeholder="Corporate Email *" 
                                            onChange={(e)=>{
                                                setEmail(e.target.value)
                                                setErrorMessage("")
                                            }}
                                            required/>
                                    </div>
                                    <div className='col-md-6 mb-2'>
                                        <input type="text" className="form-control" name="job_title" id="job_title" placeholder="Job Title *" 
                                        onChange={(e)=>{
                                            setJobTitle(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    <div className='col-md-12 mb-2'>
                                        <input type="text" className="form-control" name="comapny_Name" id="comapny_Name" placeholder="Company Name *" 
                                        onChange={(e)=>{
                                            setCompanyName(e.target.value)
                                        }}
                                        required/>
                                        
                                    </div>
                                    <div className='col-md-12 mb-2'>
                                        <input type="text" className="form-control" name="user_subject" id="user_subject" placeholder="Subject" 
                                        onChange={(e)=>{
                                            setSubject(e.target.value)
                                        }}
                                        />
                                    </div>
                                    <div className='col-md-12 mb-2'>
                                        <textarea className="form-control" name="message" rows="4" placeholder="Message" 
                                        onChange={(e)=>{
                                            setMessage(e.target.value)
                                        }}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        {
                                            errorMessage?<>
                                            <div className='alert alert-danger mb-3'>
                                            {"Error: "+ errorMessage}
                                            </div>
                                            </>:<></>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4 offset-md-4'>
                                        <div class="d-grid gap-2 col-12 mx-auto">
                                            <button type="submit" className="btn btn-block btn-info" disabled={btnDisabled}>{btnText}</button>
                                        </div>
                                    </div>
                                </div>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <button type="button" class="btn btn btn-outline-info"
            data-bs-toggle="modal" data-bs-target="#downloadProductFeatureModalID"
            title='Request Product Brief'
            ><i className='bi bi-download'></i> Product Brief</button>
               
        
    </>
  )
}

export default DownloadProductInfo