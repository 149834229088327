import React from 'react'

import { useState } from "react";
import Swal from 'sweetalert2';

function Downloads() {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [message, setMessage] = useState("");

    const [otp, setOTP] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Download");
    const [otpBoxEnable, setOtpBoxEnable] = useState(false);
    const [isMailVerified, setIsMailVerified] = useState(false);

    const downloadPageSubmit = (e) =>{
        e.preventDefault();

        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter business email');
            return false;
        }
        else
        {
            setErrorMessage("");
        }

        //return false;

        setBtnDisabled(true)
          setBtnText("Downloading...")
          fetch('https://j00u65xk8f.execute-api.ap-south-1.amazonaws.com/sendDownloadBrochureMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "user_name": firstName+ " "+lastName,
                 "user_fname": firstName,
                 "user_lname": lastName,
                 "user_email": email,
                 "user_contactNo": contactNo,
                 "user_message": message,
                 "downloadType":"Brochure",
            })
            }).then( response => {

                //Swal.fire("Success", "Mail Sent Successfully", "success");

                fetch('/downloads/Marquee.pdf').then(response => {
                    response.blob().then(blob => {
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = 'Marquee_Brochure.pdf';
                        alink.click();
                    })
                })

                setFirstName("");
                setLastName("");
                setEmail("");
                setContactNo("");
                setMessage("");
                setBtnDisabled(false)
                setBtnText("Download")

                document.getElementById("btnCloseModal").click();

                
                
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error downloading file.", "error");
            });

          e.target.reset();
    }

    /* const onMailverifyClick = e =>{

        if (!email) {
            setErrorMessage('Email address required');
            return false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorMessage('Invalid email address');
            return false;
        }
        else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/i.test(email)) {
            setErrorMessage('Please enter business email');
            return false;
        }
        else
        {
            setErrorMessage("");
        }

        fetch('https://6ftf8qvddh.execute-api.ap-south-1.amazonaws.com/sendVerificationMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "user_email": email,
            })
            }).then( response => {
                
                setOtpBoxEnable(true)
                
                
            })
            .catch(error => {
            
                Swal.fire("Error", "Error sending verification mail.", "error");
            });
        
    }

    const onSubmitOTPClick = e =>{
        if(!otp)
        {
            setErrorMessage('Please enter otp sent to your email');
            return false;
        }
        setIsMailVerified(true)
        setOtpBoxEnable(false)
        setBtnDisabled(false)
    }*/
  return (
    <>

        <div className='container-fluid'>
            <div className="modal fade" id="downloadModalID" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-success">
                            <h5 className="modal-title text-white" id="">Download Marquee brochure</h5>
                            <button type="button" id='btnCloseModal' className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={downloadPageSubmit}  enctype="multipart/form-data" method="post">
                                
                            
                                <div class="row">
                                    <div class="col mb-3">
                                        <input type="text" className="form-control" id="txtFName" placeholder="First Name" name="txtFName" 
                                        onChange={(e)=>{
                                            setFirstName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    <div class="col mb-3">
                                        <input type="text" className="form-control" id="txtLName" placeholder="Last Name" name="txtLName" 
                                        onChange={(e)=>{
                                            setLastName(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col">
                                    <input type="email" className="form-control" id="txtEmail" placeholder="Business Email" name="txtEmail" 
                                        onChange={(e)=>{
                                            setEmail(e.target.value)
                                            setErrorMessage("")
                                        }}
                                        disabled={isMailVerified}
                                        required/>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" className="form-control" id="txtContactNo" placeholder="Contact No." name="txtContactNo" 
                                        onChange={(e)=>{
                                            setContactNo(e.target.value)
                                        }}
                                        required/>
                                    </div>
                                    {/*<div class="col-8">
                                        

                                        <div class="input-group">
                                        <input type="email" className="form-control" id="txtEmail" placeholder="Business Email" name="txtEmail" 
                                        onChange={(e)=>{
                                            setEmail(e.target.value)
                                            setErrorMessage("")
                                        }}
                                        disabled={isMailVerified}
                                        required/>
                                            <div class="input-group-append">
                                                <button className={!isMailVerified?"btn btn-outline-secondary":"btn btn-success"} type="button"
                                                onClick={onMailverifyClick}
                                                disabled={isMailVerified}
                                                >
                                                    {isMailVerified?<i className='bi bi-check-circle'></i>:"Verify"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>*/}
                                    {/*
                                        otpBoxEnable?<>
                                        <div class="col-4">
                                            <div class="input-group">
                                                <input type="text" className="form-control" placeholder='OTP'
                                                onChange={(e)=>{
                                                    setOTP(e.target.value)
                                                    setErrorMessage("")
                                                }}
                                                />
                                                <div class="input-group-append">
                                                    <button className={"btn btn-outline-secondary"} type="button"
                                                    onClick={onSubmitOTPClick}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        </>:<>
                                        
                                        </>
                                            */}
                                    
                                </div>
                                
                                
                                
                                <div className="mb-3 mt-3">
                                    <textarea className="form-control" placeholder='Enter reson to download the brochure' rows="3" id='txtComment'
                                    onChange={(e)=>{
                                        setMessage(e.target.value)
                                    }}
                                    required
                                    ></textarea>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        {
                                            errorMessage?<>
                                            <div className='alert alert-danger'>
                                            {"Error: "+ errorMessage}
                                            </div>
                                            </>:<></>
                                        }
                                    </div>
                                </div>
    

                                <div className='row'>
                                    <div className='col-md-4 offset-md-4'>
                                        <button type="submit" className="btn btn-block btn-success"
                                         disabled={btnDisabled}>{btnText}</button>
                                    </div>
                                </div>      
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 downloads-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6 text-mcolor'>Downloads</h1>
                        <p className='blockquote text-info d-none'>Achieves Best-In-Class Power Performance, Area, Schedule</p>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
           {/*
            <div className='row'>
                <div className='col-md-12 mt-5 mb-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>Downloads</h3>
                    <p>
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                        The company focuses on providing the best-in-class turnkey end-to-end SoC solution – Spec to Silicon Validation with a differentiation of innovative methodologies.
                    </p>
                </div>
            </div> */}
            {/*
            <div className='row'>
                <div className='col-md-12'>
                <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Tittle</th>
                                <th scope="col">Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>providing the best-in-class turnkey end-to-end SoC solution</td>
                                <td><Link to="/downloads/Marquee.pdf" className='btn btn-sm btn-success' target="_blank" download><i class="bi bi-download"></i>  Download</Link>
                                
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>*/}
        </div>

        <div className='container-fluid pb-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='../images/brochure.jpg' alt='Marquee Simiconductor' className='img-fluid'/>
                            </div>
                            <div className='col-md-9 d-flex align-items-center justify-content-left'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Marquee brochure</span></p>
                                    <p className='text-justify'>The company was established in 2019 with a singular focus on providing the best in
                                        class end to end system solutions - Spec to Silicon or Spec to FPGA System. The
                                        company achieves high productivity with first time system success with the adoption of
                                        its DRIVE model Domain expertise, IP knowledge, Methodology and Ecosystem
                                        partnership. The leaders in the company have a successful track record in building
                                        several companies and products from ground up. The seasoned team comes from both
                                        backgrounds - designing products and providing services - with a product mindset and
                                        first pass success.</p>
                                    <button type="button" className='btn btn-sm btn-success' data-bs-toggle="modal" data-bs-target="#downloadModalID"><i class="bi bi-download"></i>  Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Downloads